import React from "react";
import { Link, Redirect } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons-bs';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import $ from 'jquery'; 
import Edit from "../assets/img/Edit.svg";
import Eye from "../assets/img/Eye.svg";
import profile from "../assets/img/profile.jpg";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

class Dashboard extends React.Component {
 
  constructor(props){
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    
    this.state = {
      user_id,
      appKey,
      no_users:0,
      no_matches:0,
      no_teams:0,
      users :[]
    }

  }
  componentDidMount() {
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
      
      $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/dashboard',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id},
        success : function(data) {
          
          
          if(data.status === true){
            
              this.setState({
                users:data.data.tournament_match,
                no_teams:data.data.nfl_team,
                no_matches:data.data.total_match,
                no_users:data.data.total_users
            })
            
          }
      }.bind(this),
      statusCode: {
        401: function() {
          window.location.href="/admin/logout/"
        }
      },
        complete : function(){
          $("#cover_spin").removeClass("cover-spin");
          var table = $('#example').DataTable( {
           
        } );
          
        
        },
           error: function (xhr,ajaxOptions,throwError){
          //Error block 
        },
      });
    });
  }
  render(){
    let logined = true;
  const userData = localStorage.getItem("userData");
  
        if(userData === null){
            logined = false;
        }
        if(logined === false){
          return <Redirect to="/admin/login"></Redirect> 
       }
    const { users ,no_users} = this.state;
   
  return (
    <div className="MainDiv content">
      <Row className="align-items-center">
        <Col lg="12">
          <h5 className="head">Dashboard</h5>
         
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="4" md="6" sm="6">
        <Link to={"/admin/users"}>
          <Card className="card-stats box1">
            <CardBody>
              <Row className="align-items-center">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-circle-10" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Users</p>
                    <CardTitle className="ak-card-digit" tag="p">{this.state.no_users}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          </Link>
        </Col>
        <Col lg="4" md="6" sm="6">
        <Link to={"/admin/tournaments"}>
          <Card className="card-stats box2">
            <CardBody>
              <Row className="align-items-center">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-money-coins" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">Matches</p>
                    <CardTitle className="ak-card-digit" tag="p">{this.state.no_matches}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          </Link>
        </Col>
        <Col lg="4" md="6" sm="6">
        <Link to={"/admin/team_list"}>
          <Card className="card-stats box-3">
            <CardBody>
              <Row className="align-items-center">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    <i className="nc-icon nc-vector" />
                  </div>
                </Col>
                <Col md="8" xs="7">
                  <div className="numbers">
                    <p className="card-category">NFL Teams</p>
                    <CardTitle className="ak-card-digit" tag="p">{this.state.no_teams}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          </Link>
        </Col>
      </Row>
      <Card className="table-view">
        <CardHeader>
          <CardTitle tag="h5">NFL Matches</CardTitle>
        </CardHeader>
        <CardBody>
          <table id="example" className="display">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Teams</th>
                <th>Match Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users.map(({id,start_date,flag,tournament_name,team1_name,team2_name}) => (
                <tr key={id.toString()}>
                  <td>{id}</td>
                  <td>{tournament_name}</td>
                  <td className="team-list">
                    <div className="d-inline-block align-middle mr-3">
                      
                      <div className="d-inline-block">
                        <span>{team1_name}</span>
                      </div>
                    </div>
                    <div className="d-inline-block align-middle mr-3 vs">VS</div>
                    <div className="d-inline-block align-middle mr-2">
                      
                      <div className="d-inline-block">
                        <span>{team2_name}</span>
                      </div>
                    </div>
                  </td>
                  <td>{start_date}</td>
                  <td className="status">
                  <span>{flag ==1 ?"Active":"Inactive"}</span>
                  </td>
                  <td>
                   <Link to={"/admin/viewmatch/"+id}>
                    <button className="edit-icon delete-icon"><img src={Eye}/></button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>  
        </CardBody>
      </Card>
      
    </div>
  );
}
}

export default Dashboard;
