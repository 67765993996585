import React from "react";
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,ContentState, convertFromHTML } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import draftToHtml from 'draftjs-to-html';
import $ from 'jquery'; 
import prediction from "./Prediction";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class edit_prediction extends React.Component{
    constructor(props) {
        super(props);
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.defaultOption = { "id": "-1", "value": "" };
        this.state = {
            user_id,
            appKey,
            question: "",
            tournament_id:"",
            match_id:"",
            algorithm_level:"",
            algorithm_line:"",
            algorithm_desc:"",
            team1_desc:"",
            team2_desc:"",
            point_table_desc:"",
            fbse:[],
            house_level:"",
            house_line:"",
            house_desc:"",
            isPaid:"",
            team1_fullname:"",
            team1_profile:"",
            team1_id:"",
            team2_fullname:"",
            team2_profile:"",
            team2_id:"",
            algorithm_level_team:"",
            algorithm_line_team:"",
            house_level_team:"",
            house_line_team:"",
            tournaments :[],
            teams:[],
            matches:[],
            a_id: props.match.params.id,
            options: [this.defaultOption],
            predictions:[],
            predictionDetails:[],
            editorState: EditorState.createEmpty(),
            editorState_point_table:EditorState.createEmpty(),
            editorStateTeam2:EditorState.createEmpty(),
            editorStateOnhouse:EditorState.createEmpty(),
            editorStateAlgorithm:EditorState.createEmpty(),
            fbses:[]
        }
        this.getdetailstourna = this.getdetailstourna.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.textFieldChange = this.textFieldChange.bind(this);
        this.teamChange = this.teamChange.bind(this);
        this.team1Change = this.team1Change.bind(this);
        this.team2Change = this.team2Change.bind(this);
        this.team3Change = this.team3Change.bind(this);
    }
   
    teamChange = (event) =>{
       
        this.setState({
            team1_fullname:event.target.selectedOptions[0].getAttribute('team1_fullname'),
            team1_profile:event.target.selectedOptions[0].getAttribute('team1_profile'),
            team1_id:event.target.selectedOptions[0].getAttribute('team1_id'),
            team2_fullname:event.target.selectedOptions[0].getAttribute('team2_fullname'),
            team2_profile:event.target.selectedOptions[0].getAttribute('team2_profile'),
            team2_id:event.target.selectedOptions[0].getAttribute('team2_id'),

        });
        
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            team1_desc:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        });
    };

    onEditorStateChangePointTable = (editorState_point_table) => {
        this.setState({
            editorState_point_table,
            point_table_desc:draftToHtml(convertToRaw(this.state.editorState_point_table.getCurrentContent()))
        });
    };
    onEditorStateChangeTeam2 = (editorStateTeam2) => {
        this.setState({
            editorStateTeam2,
            team2_desc:draftToHtml(convertToRaw(this.state.editorStateTeam2.getCurrentContent()))
        });
    };
    onEditorStateChangeAlgorithm = (editorStateAlgorithm) => {
        this.setState({
            editorStateAlgorithm,
            algorithm_desc:draftToHtml(convertToRaw(this.state.editorStateAlgorithm.getCurrentContent()))
        });
    };
    onEditorStateChangeOnHouse = (editorStateOnhouse) => {
        this.setState({
            editorStateOnhouse,
            house_desc:draftToHtml(convertToRaw(this.state.editorStateOnhouse.getCurrentContent()))
        });
    };
    textFieldChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
    }
    handleChange = (e) => {
        let { id, value } = e.target;
        this.setState({ [id]: value })
    }
    handleOptionsChange = (index, e) => {
        let { id, value } = e.target;
        let stateOptionsClone = JSON.parse(JSON.stringify(this.state.options));
        stateOptionsClone[index].value = value;
        this.setState({ options: stateOptionsClone });
    }
    handleSave = (e) => {
        e.preventDefault();
    }
    resetQuestion = () => {
        let emptyQuestion = "";
        return emptyQuestion
    }
    resetOptions = (options) => {
        let emptyOptions = options.map(
            (data) => {
                data.value = '';
                return data;
            }
        )
        return emptyOptions;
    }
    handleReset = (e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state));
        let emptyQuestion = this.resetQuestion();
        let emptyOptions = this.resetOptions(stateClone.options);
        this.setState({ question: emptyQuestion, options: emptyOptions });
        e.preventDefault();
    }
    handleDelete = (index, e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state.options));
        stateClone.splice(index, 1);
        this.setState({ options: stateClone });
        e.preventDefault();
    }
    handleClick = (e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state));
        stateClone.options.push(this.defaultOption);
        this.setState({ options: stateClone.options });
        e.preventDefault();
    }
    
    formSubmit(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var form = $("#addPrediction");
        var ddd = form.serializeArray();
         var indexed_array = {};

        $.map(ddd, function(n, i){
            indexed_array[n['name']] = n['value'];
        });
        $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/updatePrediction',
              headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Authorization':'Basic YWRtaW46MTIzNA==',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
              data :ddd,
             
              success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
                if(data.status === true){
                    toast.success(data.msg); 
                    $("#cover_spin").removeClass("cover-spin");
                    //window.location.href="/admin/prediction";
                        
                  
                }else{
                    this.setState({
                        error:true
                    })
                }
            }.bind(this),
           
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
                toast.error(xhr.responseJSON.msg);
            },
          });
    }
    getdetailstourna(id){
         
        $(()=>{
          var id = $('#tournament_id option:selected').val();
           
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/tournamentMatchList',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id,tournament_id:id},
              success : function(data) {
                if(data.status === true){
                  
                  this.setState({
                    matches:data.data
                  })
                }
               
              
            }.bind(this),
            complete : function(){
             
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
             
          });
      }
    componentDidMount() {
        $("#cover_spin").addClass("cover-spin");
        $(()=>{
          
          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                
                  this.setState({
                    tournaments:data.data,
                    
                })
                
              }
          }.bind(this),
          statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });

          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/teamList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
               
                  this.setState({
                    teams:data.data
                })
              }
             
            
          }.bind(this),
          statusCode: {
            401: function() {
                window.location.href="/admin/logout/"
            }
        },
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
       
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/predictionDetails',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id,prediction_id:this.state.a_id},
            success : function(data) {
                
              if(data.status === true){
               
                  this.setState({
                    predictions:data.data,
                    fbses : data.data.fbse,
                    tournament_id:data.data.tournament_id,
                    match_id:data.data.match_id,
                    algorithm_level:data.data.algorithm_level,
                    algorithm_level_team:data.data.algorithm_level_team,
                    algorithm_line:data.data.algorithm_line,
                    algorithm_line_team:data.data.algorithm_line_team,
                    algorithm_desc:data.data.algorithm_desc,
                    team1_desc:data.data.team1_desc,
                    team2_desc:data.data.team2_desc,
                    point_table_desc:data.data.point_table_desc,
                    fbse:data.data.fbse,
                    house_level:data.data.house_level,
                    house_level_team:data.data.house_level_team,
                    house_line:data.data.house_line,
                    house_line_team:data.data.house_line_team,
                    house_desc:data.data.house_desc,
                    isPaid:data.data.isPaid,
                    predictionDetails:data.data,
                    editorState: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.data.team1_desc)
                        )
                      ),
                      editorStateTeam2: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.data.team2_desc)
                        )
                      ),
                      editorState_point_table: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.data.point_table_desc)
                        )
                      ),
                      editorStateAlgorithm: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.data.algorithm_desc)
                        )
                      ),
                      editorStateOnhouse: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(data.data.house_desc)
                        )
                      ),
                })
                var fbesdata = data.data.fbse;
                fbesdata.forEach(function(entry,index) {
                    document.getElementById('id_'+index).value = entry.id;
                    document.getElementById('sportBook_'+index).value = entry.sportBook;
                    document.getElementById('monday_'+index).value = entry.monday;
                    document.getElementById('wednesday_'+index).value = entry.wednesday;
                    document.getElementById('friday_'+index).value = entry.friday;
                    
                    $('#team_id_'+index+'  option[value="'+entry.team_id+'"]').prop("selected", true);
                    
                    
                });
                $.ajax({
                    type: 'POST',
                    url: window.$base_url+'/v1/auth/tournamentMatchList',
                    headers: {'App-Secret-Key': this.state.appKey,
                    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization':'Basic YWRtaW46MTIzNA=='
                  },
                    data :{user_id : this.state.user_id,tournament_id:data.data.tournament_id},
                    success : function(data) {
                      if(data.status === true){
                        
                        this.setState({
                          matches:data.data
                        })
                      }
                     
                    
                  }.bind(this),
                  statusCode: {
                    401: function() {
                      window.location.href="/admin/logout/"
                    }
                  },
                  complete : function(){
                    $("#cover_spin").removeClass("cover-spin");
                    this.setState({
                        team1_fullname:$('#match_id option:selected').attr('team1_fullname'),
                        team1_profile:$('#match_id option:selected').attr('team1_profile'),
                        team2_fullname:$('#match_id option:selected').attr('team2_fullname'),
                        team2_profile:$('#match_id option:selected').attr('team2_profile'),
                        team1_id:$('#match_id option:selected').attr('team1_id'),
                        team2_id:$('#match_id option:selected').attr('team2_id'),
            
                    });
                  }.bind(this),
                    error: function (xhr,ajaxOptions,throwError){
                    //Error block 
                  },
                });
              }
             
            
          }.bind(this),
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });

        });
        

      }
      team1Change =() =>{
       
        document.getElementById('sportBook_0').value = "";
        document.getElementById('monday_0').value = "";
        document.getElementById('wednesday_0').value = "";
        document.getElementById('friday_0').value = "";
        
      }
      team2Change =() =>{
    
        document.getElementById('sportBook_1').value = "";
        document.getElementById('monday_1').value = "";
        document.getElementById('wednesday_1').value = "";
        document.getElementById('friday_1').value = "";
      }
      team3Change =() =>{
       
        document.getElementById('sportBook_2').value = "";
        document.getElementById('monday_2').value = "";
        document.getElementById('wednesday_2').value = "";
        document.getElementById('friday_2').value = "";
      }
    render(){
       
        let logined = true;
        const userData = localStorage.getItem("userData");
  
        if(userData === null){
            logined = false;
        }
        if(logined === false){
          return <Redirect to="/admin/login"></Redirect> 
       }
       const { tournaments,teams,matches,predictions,fbses, team1_profile,team1_fullname, team2_profile, team2_fullname,team1_id, team2_id, algorithm_level_team, algorithm_line_team, house_level_team, house_line_team} = this.state;
        let { question, options } = this.state;
        const {editorState, editorState_point_table, editorStateTeam2, editorStateAlgorithm, editorStateOnhouse, predictionDetails} = this.state;
       
        
        $("#team1_desc").val(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
        $("#team2_desc").val(draftToHtml(convertToRaw(this.state.editorStateTeam2.getCurrentContent())));
        $("#point_table_desc").val(draftToHtml(convertToRaw(this.state.editorState_point_table.getCurrentContent())));
        $("#algorithm_desc").val(draftToHtml(convertToRaw(this.state.editorStateAlgorithm.getCurrentContent())));
        $("#house_desc").val(draftToHtml(convertToRaw(this.state.editorStateOnhouse.getCurrentContent())));

        // useEffect(()=>{
        //     fbses?.map((entry,index)=>{
               
        //     })
        // },[fbses]);
        // fbses?.forEach(function(entry,index) {
        //     document.getElementById('id_'+index).value = entry.id;
        //     document.getElementById('sportBook_'+index).value = entry.sportBook;
        //     document.getElementById('monday_'+index).value = entry.monday;
        //     document.getElementById('wednesday_'+index).value = entry.wednesday;
        //     document.getElementById('friday_'+index).value = entry.friday;
            
        //     $('#team_id_'+index+'  option[value="'+entry.team_id+'"]').prop("selected", true);
            
            
        // });
        if(fbses.length > 0){
            console.log("fbses : ", fbses[0]);
                document.getElementById('id_0').value = fbses[0].id;
                document.getElementById('sportBook_0').value = fbses[0].sportBook;
                document.getElementById('monday_0').value = fbses[0].monday;
                document.getElementById('wednesday_0').value = fbses[0].wednesday;
                document.getElementById('friday_0').value = fbses[0].friday;
                $('#team_id_0  option[value="'+fbses[0].team_id+'"]').prop("selected", true);

                document.getElementById('id_1').value = fbses[1].id;
                document.getElementById('sportBook_1').value = fbses[1].sportBook;
                document.getElementById('monday_1').value = fbses[1].monday;
                document.getElementById('wednesday_1').value = fbses[1].wednesday;
                document.getElementById('friday_1').value = fbses[1].friday;
                $('#team_id_1  option[value="'+fbses[1].team_id+'"]').prop("selected", true);

                document.getElementById('id_2').value = fbses[2].id;
                document.getElementById('sportBook_2').value = fbses[2].sportBook;
                document.getElementById('monday_2').value = fbses[2].monday;
                document.getElementById('wednesday_2').value = fbses[2].wednesday;
                document.getElementById('friday_2').value = fbses[2].friday;
                $('#team_id_2  option[value="'+fbses[2].team_id+'"]').prop("selected", true);
        }   
      
        return(
            <>
                <div className="MainDiv content">
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6">
                                    <h5 className="head">Update Prediction</h5>
                                   
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <form onSubmit={this.formSubmit} id="addPrediction">
                        <input type="hidden" name="user_id" value={this.state.user_id} />
                        <input type="hidden" name="prediction_id" value={predictions.id} />
                        <input type="hidden" name="team1_desc" id="team1_desc" />
                        <input type="hidden" name="team2_desc" id="team2_desc" />
                        <input type="hidden" name="point_table_desc" id="point_table_desc" />
                        <input type="hidden" name="algorithm_desc" id="algorithm_desc"  />
                        <input type="hidden" name="house_desc"   id="house_desc" />
                        <Card className="mt-4">
                            <CardBody>
                                
                                    <Row>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">Select Tournaments</label><br/>
                                                <select name="tournament_id" id="tournament_id" onChange={() => this.getdetailstourna(this.value)} className="selectbar">
                                                <option value="" >Select Tournaments</option>
                                                {tournaments.map(({id,name}) => (
                                                <option value={id}  selected={predictions.tournament_id === id ?true:false}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">Select Match</label><br/>
                                                <select name="match_id" id="match_id" onChange={this.teamChange} className="selectbar">
                                                    <option value="">Select Match</option>
                                                    {matches.map(({id,team1_name,team2_name, team1_fullname,team1_profile,team2_fullname, team2_profile,team1_id, team2_id}) => (
                                                        <option value={id}  selected={predictions.match_id === id ?true:false}
                                                        team1_fullname={team1_fullname} 
                                                        team1_profile={team1_profile} 
                                                        team2_fullname={team2_fullname}
                                                        team2_profile={team2_profile}
                                                        team1_id={team1_id}
                                                        team2_id={team2_id}
                                                        >{team1_name+" VS "+team2_name} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">FREE / PAID</label><br/>
                                                <select id="isPaid" name="isPaid" defaultValue={predictions.isPaid} className="selectbar">
                                                    <option >Select Match</option>
                                                    <option value="0" selected={predictions.isPaid == 0?true:false}>FREE</option>
                                                    <option value="1" selected={predictions.isPaid == 1?true:false}>PAID</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        {team1_profile !== ""&&<><Col lg="6">
                                        <img className="img-40 mr-2 rounded-circle align-top" style={{width:'50px',height:'50px'}} src={'https://admin.hiphopandhomeruns.com/API/uploads/team/'+team1_profile} alt={team1_fullname}/>
                                        <p>{team1_fullname}</p>
                                        </Col>  
                                        <Col lg="6">
                                            <img className="img-40 mr-2 rounded-circle align-top" style={{width:'50px',height:'50px'}} src={'https://admin.hiphopandhomeruns.com/API/uploads/team/'+team2_profile} alt={team2_fullname}/>
                                            <p>{team2_fullname}</p>            
                                        </Col></>}
                                        </Row>
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">Algorithm</h5>
                                
                                    <Row>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level</label>
                                                <input type="number" step="any" name="algorithm_level" id="algorithm_level" value={this.state.algorithm_level} className="form-control"
                                                onChange={this.textFieldChange}
                                                required/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level For Team</label>
                                               <select id="algorithm_level_team" name="algorithm_level_team" className="selectbar">
                                                   <option value={team1_id} selected={team1_id == algorithm_level_team?true:false}>{team1_fullname}</option>
                                                    <option value={team2_id} selected={team2_id == algorithm_level_team?true:false}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line</label>
                                                <input type="number" step="any" name="algorithm_line" id="algorithm_line" onChange={this.textFieldChange} value={this.state.algorithm_line} className="form-control" required/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line For Team</label>
                                                <select id="algorithm_line_team" name="algorithm_line_team"  className="selectbar">
                                                   <option value={team1_id} selected={team1_id == algorithm_line_team?true:false}>{team1_fullname}</option>
                                                    <option value={team2_id} selected={team2_id == algorithm_line_team?true:false}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <Editor
                                                editorState={editorStateAlgorithm}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeAlgorithm}
                                            />
                                            </div>
                                        </Col>
                                    
                                    </Row>
                            
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">Trend & Analysis</h5>
                                
                                    <Row>
                                    <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Team 1 Description</label>
                                                <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Team 2 Description</label>
                                                <Editor
                                                editorState={editorStateTeam2}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeTeam2}
                                            />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Point Table Description</label>
                                                <Editor
                                                editorState={editorState_point_table}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangePointTable}
                                            />
                                            </div>
                                        </Col>
                                    
                                    </Row>
                                
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">FBSE</h5>
                                                        
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 1</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[0][team_id]" id="team_id_0" onChange={this.team1Change} className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                        <input type="hidden" name="fbse[0][id]" id="id_0" />
                                            <div className="form-group">
                                                <input type="text" name="fbse[0][sportBook]" id="sportBook_0"  className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][monday]" step="any"   id="monday_0" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][wednesday]" step="any"   id="wednesday_0" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][friday]" step="any"   id="friday_0" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 2</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[1][team_id]"  id="team_id_1" onChange={this.team2Change}  className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                        <input type="hidden" name="fbse[1][id]" id="id_1" />
                                            <div className="form-group">
                                                <input type="text" name="fbse[1][sportBook]" id="sportBook_1" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][monday]"  step="any" id="monday_1" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][wednesday]"  step="any" id="wednesday_1" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][friday]"  step="any" id="friday_1" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 3</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[2][team_id]" id="team_id_2" onChange={this.team3Change}  className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                        <input type="hidden" name="fbse[2][id]" id="id_2" />
                                            <div className="form-group">
                                                <input type="text" name="fbse[2][sportBook]" id="sportBook_2"  className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][monday]"  step="any" id="monday_2" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][wednesday]"  step="any" id="wednesday_2" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][friday]"  step="any" id="friday_2" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                
                                
                            </CardBody>
                        </Card>
                        
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">On The House</h5>
                                
                                    <Row>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level</label>
                                                <input type="number" step="any" name="house_level" onChange={this.textFieldChange} value={this.state.house_level} id="house_level" className="form-control" placeholder="Enter Leval"/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level For Team</label>
                                                <select id="house_level_team" name="house_level_team" className="selectbar">
                                                   <option value={team1_id} selected={team1_id == house_level_team?true:false}>{team1_fullname}</option>
                                                    <option value={team2_id} selected={team2_id == house_level_team?true:false}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line</label>
                                                <input type="number" step="any" name="house_line" id="house_line" onChange={this.textFieldChange} value={this.state.house_line} className="form-control" placeholder="Enter Line"/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line For Team</label>
                                                <select id="house_line_team" name="house_line_team" className="selectbar">
                                                    <option value={team1_id} selected={team1_id == house_line_team?true:false}>{team1_fullname}</option>
                                                    <option value={team2_id} selected={team2_id == house_line_team?true:false}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                
                                                <Editor
                                                editorState={editorStateOnhouse}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeOnHouse}
                                            />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="text-right">
                                                <button type="submit" className="blue-btn mr-0">Update</button>
                                            </div>
                                        </Col>
                                    </Row>
                                
                            </CardBody>
                        </Card>
                    </form>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default edit_prediction;
