import React from "react";
import { useEffect } from "react";
import { Player } from 'video-react';
import video from "assets/img/football.jpg";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,ContentState, convertFromHTML } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import draftToHtml from 'draftjs-to-html';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";


class Home extends React.Component{
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        
        this.state = {
            user_id,
            appKey,
            title:"",
            algoritham_stats:"",
            house_pick_stats:"",
            brief_desc:"",
            videos:"",
            dates:"",
            selectedFile: null,
            about_app:"",
            editorState: EditorState.createEmpty(),
            editorStateRecapweek:EditorState.createEmpty(),
            editorStateAlgorithmState:EditorState.createEmpty(),
            editorStateHousePick:EditorState.createEmpty(),
            contentState: {},
            editorStateAbout:EditorState.createEmpty(),
        }
        this.textFieldChange = this.textFieldChange.bind(this);
        this.frmsubmit = this.frmsubmit.bind(this);
    }
    onChangeHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        });
        
        
    };
    frmsubmit(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var form = $("#frmHome");
        var frmdata = new FormData();
        //frmdata = form.serializeArray();
        var brief_desc = $("#brief_desc").val();
        var title = $("#title").val();
        var algoritham_stats = $("#algoritham_stats").val();
        var house_pick_stats = $("#house_pick_stats").val();
        var about_app = $("#about_app").val();
        
        var files = $('#video')[0].files;
        frmdata.append('brief_desc',brief_desc);
        frmdata.append('title',title);
        frmdata.append('algoritham_stats',algoritham_stats);
        frmdata.append('house_pick_stats',house_pick_stats);
        frmdata.append('about_app',about_app);
        frmdata.append('user_id',this.state.user_id);
        // Check file selected or not
        if(files.length > 0 ){
            frmdata.append('video',files[0]);
           
        }
        $.ajax({
                type: 'POST',
                url:  window.$base_url+'/v1/auth/updateHomepage',
                    headers: {'App-Secret-Key': this.state.appKey,
                    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                    'Authorization':'Basic YWRtaW46MTIzNA==',
                    
                },
            
            data :frmdata,
            contentType: false,
            processData: false,
            timeout: 600000,
            dataType: 'json',
            success : function(data) {
            if(data.status === true){ 
                toast.success(data.msg); 
                $("#cover_spin").removeClass("cover-spin");
                window.location.reload(true);
            }
            }.bind(this),
                complete : function(){
            },
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
                toast.error(xhr.responseJSON.msg);
            },
        });
        
    }
    textFieldChange(e){
        this.setState({
            [e.target.name] : e.target.value,
        })	 
    }
    onEditorStateChangeRecapPreviousWeek = (editorStateRecapweek) => {
        
        this.setState({
            editorStateRecapweek,
            brief_desc:draftToHtml(convertToRaw(this.state.editorStateRecapweek.getCurrentContent()))
        });
    };
    
    // onContentStateChange(contentState){
    //     this.setState({
    //         editorStateRecapweek,
    //         brief_desc:draftToHtml(convertToRaw(this.state.editorStateRecapweek.getCurrentContent()))
    //     });
    //   };
    onEditorStateChangeAlgorithState = (editorStateAlgorithmState) => {
        this.setState({
            editorStateAlgorithmState,
            algoritham_stats:draftToHtml(convertToRaw(this.state.editorStateAlgorithmState.getCurrentContent()))
        });
    };
    onEditorStateChangeHousePick = (editorStateHousePick) => {
        this.setState({
            editorStateHousePick,
            house_pick_stats:draftToHtml(convertToRaw(this.state.editorStateHousePick.getCurrentContent()))
        });
    };
    onEditorStateChangeAbout = (editorStateAbout) => {
        this.setState({
            editorStateAbout,
            about_app:draftToHtml(convertToRaw(this.state.editorStateAbout.getCurrentContent()))
        });
    };
    onEditorStateChange = (editorState) => {
       
        this.setState({
            editorState,
            brief_desc:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        });
    };
   
    componentDidMount(){
        $(()=>{
           
            $.ajax({
                type: 'POST',
                url: window.$base_url+'/v1/auth/homepageDetails',
                headers: {'App-Secret-Key': this.state.appKey,
                'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                'Authorization':'Basic YWRtaW46MTIzNA==',
                
            },
              data :{user_id : this.state.user_id},
              success : function(data) {
                if(data.status === true){
                    this.setState({
                        editorState: EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(data.data.brief_desc)
                            )
                          ),
                          title:data.data.title,
                          algoritham_stats:data.data.algoritham_stats,
                          house_pick_stats:data.data.house_pick_stats,
                          videos:data.data.videos,
                          dates:data.data.uploaded_date,
                          brief_desc:data.data.brief_desc,
                          about_app:data.data.about_app,
                          editorStateRecapweek: EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(data.data.brief_desc)
                            )
                          ),
                          editorStateAlgorithmState: EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(data.data.algoritham_stats)
                            )
                          ), 
                          editorStateHousePick: EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(data.data.house_pick_stats)
                            )
                          ),   
                          editorStateAbout: EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                              convertFromHTML(data.data.about_app)
                            )
                          ),   
                      
                  })
                  
                }
            }.bind(this),
            statusCode: {
                401: function() {
                  window.location.href="/admin/logout/"
                }
              },
              complete : function(){
                
              },
                error: function (xhr,ajaxOptions,throwError){
                //Error block 
              },
            });
        })
    }
    
    render(){
        const {editorState, editorStateRecapweek, editorStateAlgorithmState, editorStateHousePick,editorStateAbout} = this.state;
       
        $("#brief_desc").val(draftToHtml(convertToRaw(this.state.editorStateRecapweek.getCurrentContent())));
        $("#algoritham_stats").val(draftToHtml(convertToRaw(this.state.editorStateAlgorithmState.getCurrentContent())));
        $("#house_pick_stats").val(draftToHtml(convertToRaw(this.state.editorStateHousePick.getCurrentContent())));
        $("#about_app").val(draftToHtml(convertToRaw(this.state.editorStateAbout.getCurrentContent())));
        
        return(
            <>
                <div className="MainDiv content">
                <form id="frmHome" onSubmit={this.frmsubmit} encType="multipart/form-data">
                <input type="hidden" name="user_id" value={this.state.user_id} />
                <input type="hidden" name="brief_desc"  id="brief_desc"  />
                <input type="hidden" name="algoritham_stats" id="algoritham_stats"  />
                <input type="hidden" name="house_pick_stats" id="house_pick_stats"   />
                <input type="hidden" name="about_app" id="about_app"  />
                                                                           
                    <Row className="align-items-center">
                        <Col lg="12">
                            <h5 className="head">App Home</h5>
                           
                        </Col>
                    </Row>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col lg="6">
                                    <Player poster={video} src={this.state.videos}>
                                        
                                    </Player>
                                </Col>
                                <Col lg="6">
                                    <div className="video-detail">
                                        <Row>
                                            <Col lg="6">
                                                <div className="video-content">
                                                    <h5>Title</h5>
                                                    <p>{this.state.title}</p>
                                                </div>
                                            </Col>
                                           
                                           
                                        </Row>
                                        <hr/>
                                        <Row>
                                            <Col lg="12" className="">
                                                <div className="video-content upload-video">
                                                    <h5>Upload New Video</h5>
                                                    <input type="file" name="video" id="video" accept="video/*" onChange={this.onChangeHandler}  className="mt-2"/>
                                                </div>
                                            </Col>
                                           
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody className="breakdown-main">
                            <h5 className="mb-4">Add Recap Breackdown From Previous Week</h5>
                            

                                <Row>
                                <Col lg="12">
                                        <div className="form-group">
                                            <label for="title" className="form-label">Title</label><br/>
                                            <input type="text" className="form-control" name="title" id="title" 
                                            value={this.state.title} 
                                            onChange={this.textFieldChange}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="form-group">
                                            <label htmlFor="brief_desc" className="form-label">Enter Recap Previous Week</label><br/>
                                            <Editor
                                                    editorState={editorStateRecapweek}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={this.onEditorStateChangeRecapPreviousWeek}
                                                    
                                                   
                                                />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="form-group">
                                            <label htmlFor="algoritham_stats" className="form-label">Enter Algorithm Stats</label><br/>
                                           <Editor
                                                editorState={editorStateAlgorithmState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeAlgorithState}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="form-group">
                                            <label htmlFor="house_pick_stats" className="form-label">Enter Housepick Stats</label><br/>
                                           <Editor
                                                editorState={editorStateHousePick}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeHousePick}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="form-group">
                                            <label htmlFor="about_app" className="form-label">App About</label><br/>
                                            
                                            <Editor
                                                editorState={editorStateAbout}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeAbout}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12" className="mt-2 text-right">
                                        <button type="submit" className="blue-btn">Publish</button>
                                        
                                    </Col>
                                </Row>
                           
                        </CardBody>
                    </Card>
                    </form>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}
export default Home;