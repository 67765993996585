import React from "react";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { render } from "react-dom";
import { Component } from "react";
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
const createBase64 = (image) =>{
    var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            //console.log(reader.result);
            var str = reader.result;
            str = str.replace("data:image/jpeg;base64,", "");
            str = str.replace("data:image/gif;base64,", "");
            str = str.replace("data:image/png;base64,", "");
            str = str.replace("data:image/jpg;base64,", "");
            $("#bannerEdit").val(str);
            
         return reader.result;
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }
class Tournaments_list extends Component{
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
        const users = JSON.parse(userData);
        user_id = users.id;
        }
        this.state = {
            user_id,
            appKey,
            users :[],
            addTname:"",
            addTStart:"",
            addTend:"",
            editTname:"",
            editTStart:"",
            editTend:"",
            tournament_id:"",
            banner: "",
            user_id,
            file: null,
            selectf:null
            
        }
        this.textFieldChange = this.textFieldChange.bind(this);
        this.AddfrmSubmit = this.AddfrmSubmit.bind(this);
        this.deleteTournament = this.deleteTournament.bind(this);
        this.getTournamentInfo = this.getTournamentInfo.bind(this);
        this.updateTournament = this.updateTournament.bind(this);
        this.onChange = this.onChange.bind(this);
        this.resetFile = this.resetFile.bind(this);
        this.updateBackground = this.updateBackground.bind(this);
    }
    updateBackground(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        $(()=>{
          var appKeyy = this.state.appKey;
          var userId = this.state.user_id;
          var banner = $("#bannerEdit").val();
          var fileInput = $("#tournamentBackg")[0];
       var files = fileInput.files && fileInput.files[0];
                
                if( files ) {
                    var img = new Image();
            
                    img.src = window.URL.createObjectURL( files );
            
                    img.onload = function() {
                        var width = img.naturalWidth,
                            height = img.naturalHeight;
                            var r_with = height * 4.5;
                            console.log(height+" ==  "+width+"   Size :"+files.size);
                            if(height >= 200 && height <= 260 && r_with >= 900 && r_with <= 1170 && files.size <= 2098152){
                              //if(1 === 1){
                              $.ajax({
                                    type: 'POST',
                                    url: window.$base_url+'/v1/auth/vsGif',
                                    headers: {'App-Secret-Key': appKeyy,
                                    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    'Authorization':'Basic YWRtaW46MTIzNA=='
                                },
                                    data :{user_id : userId,gif:banner},
                                    success : function(data) {
                                    if(data.status === true){
                                        $("#cover_spin").removeClass("cover-spin");
                                        toast.success(data.msg); 
                                        window.location.reload(true);
                                        this.setState({
                                            
                                        })
                                    }else{
                                        $("#cover_spin").removeClass("cover-spin");
                                        toast.error(data.msg);
                                    }
                                    
                                    
                                }.bind(this),
                                complete : function(){
                                
                                },
                                    error: function (xhr,ajaxOptions,throwError){
                                    $("#cover_spin").removeClass("cover-spin");
                                    toast.error(xhr.responseJSON.msg);
                                },
                                });  
                            }else{
                                $("#cover_spin").removeClass("cover-spin");
                               
                                toast.error("Invalid Image Size!"); 
                            } 
                      
                    };
                }
        
           
        });
      }
    onChange(event) {
        this.setState({
          file: URL.createObjectURL(event.target.files[0]),
          image_src : event.target.files[0],
          banner_img : createBase64(event.target.files[0]),
          selectf:true
        });
    }
    resetFile(event) {
        event.preventDefault();
        this.setState({ file: null,selectf: false });
        $("#bannerEdit").val("");
    }
    updateTournament(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var name = $("#editTname").val();
        var start_date = $("#editTStart").val();
        var end_date = $("#editTend").val();
        var id = $("#tournamentsId_edit").val();
       
        $.ajax({
          type: 'POST',
          url:  window.$base_url+'/v1/auth/updateTournament',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
            },
            data :{user_id : this.state.user_id,name:name,start_date:start_date,end_date:end_date,tournament_id:id},
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
                    if(data.status === true){ 
                        toast.success(data.msg);
                        
                        this.setState({
                            error:true
                        })
                        window.location.reload(true);
                    }else{
                    toast.error(data.msg);
                   
                }
               
          }.bind(this),
              complete : function(){
          },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg); 
          },
        });
      
      }
      AddfrmSubmit(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
             var name = $("#addTname").val();
             var start_date = $("#addTStart").val();
             var end_date = $("#addTend").val();
            
            
             $.ajax({
              type: 'POST',
              url:  window.$base_url+'/v1/auth/addTournament',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
               },
              data :{user_id : this.state.user_id,name:name,start_date:start_date,end_date:end_date},
              success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
                if(data.status === true){ 
                    toast.success(data.msg);
                  window.location.reload(true);
                }else{
                    
                   
                }
              }.bind(this),
              statusCode: {
                406: function(data) {
                    
                }
              },
                  complete : function(){
              },
              error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
              
                toast.error(xhr.responseJSON.msg); 
              },
          });
      
      }
      
        
      getTournamentInfo(id){
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
          
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentDetails',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id,tournament_id:id},
            success : function(data) {
              if(data.status === true){
                $("#cover_spin").removeClass("cover-spin");
                this.setState({
                  editTname : data.data.name,
                  editTStart : data.data.start_date,
                  editTend : data.data.end_date,
                  tournament_id : data.data.id,
                })
              }
             
            
          }.bind(this),
          complete : function(){
           
          },
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
              
                toast.error(xhr.responseJSON.msg); 
          },
        });
           
        });
      }
      deleteTournament(id){
        confirmAlert({
            title: '',
            message: 'Are you sure want to delete?.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    $(()=>{
                        $("#cover_spin").addClass("cover-spin");
                      
                    $.ajax({
                        type: 'POST',
                        url: window.$base_url+'/v1/auth/deleteTournament',
                        headers: {'App-Secret-Key': this.state.appKey,
                        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization':'Basic YWRtaW46MTIzNA=='
                      },
                        data :{user_id : this.state.user_id,tournament_id:id},
                        success : function(data) {
                          if(data.status === true){
                            toast.success(data.msg);
                            $("#cover_spin").removeClass("cover-spin");
                            window.location.reload(true);
                            this.setState({
                                
                            })
                          }
                         
                        
                      }.bind(this),
                      complete : function(){
                       
                      },
                        error: function (xhr,ajaxOptions,throwError){
                            $("#cover_spin").removeClass("cover-spin");
                          
                            toast.error(xhr.responseJSON.msg); 
                      },
                    });
                       
                    });
                }
              },
              {
                label: 'No',
                
              }
            ]
          })
      }
    textFieldChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })
    }
    componentDidMount() {
  
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
          
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentList',
            headers: {'App-Secret-Key':  this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
              if(data.status === true){
               
                  this.setState({
                    users:data.data
                })
              }
             
            
          }.bind(this),
          statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
              
                toast.error(xhr?.responseJSON?.msg); 
          },
        });
           
        });
      }
      render(){
        const { users } = this.state;
    return(
        <>
            {users.map(({id,name,start_date,end_date}) => (
            <Col key={id.toString()} lg="3" md="6" sm="6">
                <div className="tournaments-main">
                    <h5>{name}</h5>
                    <div className="tournaments-detail">
                        <div className="detail">
                            <p>Start Date</p>
                            <span>{start_date}</span>
                        </div>
                        <div className="detail">
                            <p>End Date</p>
                            <span>{end_date}</span>
                        </div>
                        <div className="detail">
                            <p>Matches</p>
                            <span>40</span>
                        </div>
                        <Row>
                            <Col lg="6" md="6" sm="6" className="tournament-btn">
                                <button type="button" onClick={() => this.getTournamentInfo(id)} className="stadium_edit" data-target="#quoteForm1" data-toggle="modal">Edit</button>
                            </Col>
                            <Col lg="6" md="6" sm="6" className="p-lg-0 tournament-btn">
                                <button onClick={() => this.deleteTournament(id)} type="button" className="stadium_delete">Delete</button>
                            </Col>
                            <Col lg="12" className="mt-lg-3">
                                <Link to={"./matchlist/"+id} >
                                <button className="view_match">View Matches</button>
                                </Link>
                            </Col>
                            <Col lg="12" className="mt-2">
                                <Link to={"./add_match/"+id}>
                                    <button className="add_match">Add New Match</button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
            ))}
            {users?.length == 0 &&
                <Col className="text-center" lg="3" md="6" sm="6">
                        <h3> Not Record Found</h3>
                </Col>
                    
               
            }
            <div className="add-edit-team">
                <div className="modal fade" id="quoteForm" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={this.AddfrmSubmit} className="add-tournaments">
                                    <Row>
                                        <div className="col-12 mb-3">
                                            <h5 className="tour-head">Add Tournament</h5>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label htmlFor="addTname">Enter Tournament Name</label>
                                                <input type="text" className="form-control" id="addTname" name="addTname" placeholder="Enter Tournament Name"
                                                value={this.state.addTname}
                                                onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="addTStart">Start Date</label>
                                                <input type="date" className="form-control" name="addTStart" id="addTStart" 
                                                    value={this.state.addTStart}
                                                    onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="addTend">End Date</label>
                                                <input type="date" className="form-control" id="addTend"  name="addTend"
                                                value={this.state.addTend}
                                                onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <hr className="border-hr"/>
                                        <div className="col-12 text-right">
                                            <button type="submit" className="blue-btn">Add Tournament</button>
                                            <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-edit-team">
                <div className="modal fade" id="matchBackground" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <form className="add-stadium" onSubmit={this.updateBackground}>
                            <input type="hidden" id="bannerEdit" name="bannerEdit"  />
                            
                                  <div className="col-12 p-0">
                                      <div className="position-relative">
                                          <img style={{ width: "100%" }} src={this.state.file} className="stadium_image"/>
                                          <div className="upload-stadium-main">
                                              <input type="file" accept="image/*" id="tournamentBackg" onChange={this.onChange} required/>
                                          </div>
                                      </div>
                                      {this.state.selectf && (
                                          <div className="ml-3 text-center">
                                              <button onClick={this.resetFile} className="red-btn mt-2">Remove File</button>
                                          </div>
                                      )}
                                  </div>
                                  <div className="error-msg">Image size should be less than 2MB with height 240px and width 1080px</div>
                                  <div className="">
                                      <Row>
                                          
                                          
                                          
                                          <hr className="border-hr"/>
                                          <div className="col-12 text-right">
                                              <button type="submit" className="blue-btn">Tournament Background</button>
                                              <button type="button" className="red-btn" data-dismiss="modal">Cancel</button>
                                          </div>
                                      </Row>
                                  </div>
                              </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="add-edit-team">
                <div className="modal fade" id="quoteForm1" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <form onSubmit={this.updateTournament} className="add-tournaments">
                                <input type="hidden" id="tournamentsId_edit" name="tournamentsId_edit" value={this.state.tournament_id}  />
                                    <Row>
                                        <div className="col-12 mb-3">
                                            <h5 className="tour-head">Update Tournament</h5>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="editTname">Enter Tournament Name</label>
                                                <input type="text" className="form-control" id="editTname" name="editTname"  placeholder="Enter Tournament Name"
                                                value={this.state.editTname}
                                                onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="editTStart">Start Date</label>
                                                <input type="date" className="form-control" id="editTStart" name="editTStart"
                                                value={this.state.editTStart}
                                                onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="editTend">End Date</label>
                                                <input type="date" className="form-control" id="editTend" name="editTend"
                                                value={this.state.editTend}
                                                onChange={this.textFieldChange}
                                                />
                                            </div>
                                        </div>
                                        <hr className="border-hr"/>
                                        <div className="col-12 text-right">
                                            <button type="submit" className="blue-btn">Update Tournament</button>
                                            <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
        </>
    );
}
}

export default Tournaments_list;