import React from "react";
import { Link, Redirect } from "react-router-dom";
import Tournaments_list from "components/Tournaments/Tournaments_list";


// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

class tournaments extends React.Component {
  constructor(props){
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    
    this.state = {
      user_id,
      appKey,
      no_users:0,
      no_matches:0,
      no_teams:0,
      users :[]
    }

  }
  render() {
    let logined = true;
    const userData = localStorage.getItem("userData");
    
          if(userData === null){
              logined = false;
          }
          if(logined === false){
            return <Redirect to="/admin/login"></Redirect> 
         }
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="6" md="12">
                  <h5 className="head">Tournament List</h5>
                  
                </Col>
                <Col lg="6" md="12">
                  <div className="text-lg-right text-md-left add-main-btn">
                    <button className="mb-3 blue-btn" data-target="#quoteForm" data-toggle="modal">Add Tournament</button>
                    <button className="mb-3 blue-btn" data-target="#matchBackground" data-toggle="modal">Tournament Background</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12" className="mb-2">
              <div className="row">
               
                <Tournaments_list>
                 
                </Tournaments_list>
              </div>
            </Col>
          </Row>
        </div>
        
      </>
    );
  }
}

export default tournaments;
