import Dashboard from "views/Dashboard.js";
import Userlist from "views/User.js";
import Userdetail from "views/User_detail.js";
import teamlist from "views/Team_list.js";
import Stadiumlist from "views/Stadium_list.js";
import Tournaments from "views/Tournaments.js";

import Home from "views/Home.js"; 
import prediction from "views/Prediction.js";
import Social from "views/Social.js";
import Advertise from "views/Advertise";
import React from "react";
import { Link, Redirect} from "react-router-dom";
const Logout = () =>{
  localStorage.removeItem("userData");
  return <Redirect to="/admin/login"></Redirect>
}
var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-th-large",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/tournaments",
    name: "Tournament List",
    icon: "nc-icon nc-tile-56",
    component: Tournaments,
    layout: "/admin",
  },
  
  {
    path: "/team_list",
    name: "Team List",
    icon: "fas fa-user",
    component: teamlist,
    layout: "/admin",
  },
  {
    path: "/stadium_list",
    name: "Stadium List",
    icon: "fas fa-football-ball",
    component: Stadiumlist,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "User List",
    icon: "fas fa-user",
    component: Userlist,
    layout: "/admin",
  },
  /*{
    path: "/matchlist/:id",
    name: "Match List",
    icon: "nc-icon nc-caps-small",
    component: matchlist,
    layout: "/admin",
  },*/
  {
    path: "/home",
    name: "App Home",
    icon: "fas fa-home",
    component: Home,
    layout: "/admin",
  },
  {
    path: "/prediction",
    name: "Prediction",
    icon: "nc-icon nc-caps-small",
    component: prediction,
    layout: "/admin",
  },
  {
    path: "/social",
    name: "Socials Link",
    icon: "fas fa-share-alt",
    component: Social,
    layout: "/admin",
  },
  {
    path: "/advertise",
    name: "Endorsements URL",
    icon: "fas fa-share-alt",
    component: Advertise,
    layout: "/admin",
  },
 /* {
    path: "/logout",
    name: "Logout",
    icon: "fas fa-sign-out-alt",
    component: Logout,
    layout: "/admin",
  } */
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "fas fa-share-alt",
  //   component: Login,
  //   layout: "/admin",
  // },
];

export default routes;
