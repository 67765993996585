import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import $ from 'jquery';
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Edit from "../assets/img/Edit.svg";
import Delete from "../assets/img/Delete.svg";
import Eye from "../assets/img/Eye.svg";
import { Link,Redirect } from "react-router-dom";
import profile from "../assets/img/profile.jpg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Export from "components/Export/Export";
import Import from "components/Import/Import";
const createBase64houseLevelIcon = (image) =>{
    var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            //console.log(reader.result);
            var str = reader.result;
            str = str.replace("data:image/jpeg;base64,", "");
            str = str.replace("data:image/gif;base64,", "");
            str = str.replace("data:image/png;base64,", "");
            str = str.replace("data:image/jpg;base64,", "");
            $("#houseLevelIcon").val(str);
            
         return reader.result;
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }
    const createBase64algorithamIcon = (image) =>{
        var reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = function () {
                //console.log(reader.result);
                var str = reader.result;
                str = str.replace("data:image/jpeg;base64,", "");
                str = str.replace("data:image/gif;base64,", "");
                str = str.replace("data:image/png;base64,", "");
                str = str.replace("data:image/jpg;base64,", "");
                $("#algorithamIcon").val(str);
                
             return reader.result;
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
        }
class prediction extends React.Component{
    
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.state = {
            user_id,
            appKey,
            users :[],
            algorithamIcon:null,
            houseLevelIcon:null,
            filealgorithamIcon:null,
            filehouseLevelIcon:null

        }
        this.deleteTeams = this.deleteTeams.bind(this);
        this.onChangealgorithamIcon = this.onChangealgorithamIcon.bind(this);
        this.onChangehouseLevelIcon = this.onChangehouseLevelIcon.bind(this);
        this.resetFilehouseLevelIcon = this.resetFilehouseLevelIcon.bind(this);
        this.resetFilealgorithamIcon = this.resetFilealgorithamIcon.bind(this);
        this.updateLevelIcon = this.updateLevelIcon.bind(this);
       
    }
    updateLevelIcon(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        $(()=>{
         
          var algorithamIcon = $("#algorithamIcon").val();
          var houseLevelIcon = $("#houseLevelIcon").val();
                $.ajax({
                    type: 'POST',
                    url: window.$base_url+'/v1/auth/addlevelIcon',
                    headers: {'App-Secret-Key': this.state.appKey,
                    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization':'Basic YWRtaW46MTIzNA=='
                },
                    data :{user_id : this.state.user_id,algorithamIcon:algorithamIcon,houseLevelIcon:houseLevelIcon},
                    success : function(data) {
                    if(data.status === true){
                        $("#cover_spin").removeClass("cover-spin");
                        toast.success(data.msg); 
                        window.location.reload(true);
                        this.setState({
                            
                        })
                    }else{
                        $("#cover_spin").removeClass("cover-spin");
                        toast.error(data.msg);
                    }
                    
                    
                }.bind(this),
                complete : function(){
                
                },
                    error: function (xhr,ajaxOptions,throwError){
                    $("#cover_spin").removeClass("cover-spin");
                    toast.error(xhr.responseJSON.msg);
                },
            }); 
        });
      }
    onChangealgorithamIcon(event) {
        var ufiles = event.target.files[0];
        if( ufiles ) {
            var img = new Image();
            
            img.src = window.URL.createObjectURL( ufiles );

            img.onload = function() {
                var width = img.naturalWidth,
                height = img.naturalHeight;
                
                console.log(height+" ==  "+width+"   Size :"+ufiles.size);
                if(height == width && ufiles.size <= 1048570){
                    this.setState({
                        filealgorithamIcon: URL.createObjectURL(ufiles),
                        image_src : ufiles,
                        banner_img : createBase64algorithamIcon(ufiles),
                        selectfalgo:true
                      });
                }else{
                    toast.error("Image Size Is  Invalid");
                    this.setState({ filealgorithamIcon: null,selectfalgo: false });
                    $('#falgorithamIcon').val('');
                    $("#algorithamIcon").val("");
                }
            }.bind(this)
        }
       
    }
    onChangehouseLevelIcon(event) {
        var ufiles = event.target.files[0];
        if( ufiles ) {
            var img = new Image();
            
            img.src = window.URL.createObjectURL( ufiles );

            img.onload = function() {
                var width = img.naturalWidth,
                height = img.naturalHeight;
                
                console.log(height+" ==  "+width+"   Size :"+ufiles.size);
                if(height == width && ufiles.size <= 1048570){
                    this.setState({
                        filehouseLevelIcon: URL.createObjectURL(ufiles),
                        image_src : ufiles,
                        banner_img : createBase64houseLevelIcon(ufiles),
                        selectfhouse:true
                    });
                }else{
                    toast.error("Image Size Is  Invalid");
                    this.setState({ filehouseLevelIcon: null,selectfhouse: false });
                    $('#fhouseLevelIcon').val('');
                    $("#houseLevelIcon").val("");
                }
            }.bind(this)
        }
       
    }
    resetFilehouseLevelIcon(event) {
        event.preventDefault();
        this.setState({ filehouseLevelIcon: null,selectfhouse: false });
        
        $('#fhouseLevelIcon').val('');
        $("#houseLevelIcon").val("");
    }
    resetFilealgorithamIcon(event) {
        event.preventDefault();
        this.setState({ filealgorithamIcon: null,selectfalgo: false });
        $('#falgorithamIcon').val('');
        $("#algorithamIcon").val("");
    }
    deleteTeams(id){
        confirmAlert({
            title: '',
            message: 'Are you sure want to delete?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                    $(()=>{
                    $("#cover_spin").addClass("cover-spin");
                    
                    $.ajax({
                        type: 'POST',
                        url: window.$base_url+'/v1/auth/deletePrediction',
                        headers: {'App-Secret-Key': this.state.appKey,
                        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization':'Basic YWRtaW46MTIzNA=='
                    },
                        data :{user_id : this.state.user_id,prediction_id:id},
                        success : function(data) {
                            $("#cover_spin").removeClass("cover-spin");
                        if(data.status === true){
                        
                            toast.success(data.msg); 
                            window.location.reload(true);
                            this.setState({
                                
                            })
                        }
                        
                        
                    }.bind(this),
                    statusCode: {
                        401: function() {
                        window.location.href="/admin/logout/"
                        }
                    },
                    complete : function(){
                    
                    },
                        error: function (xhr,ajaxOptions,throwError){
                        //Error block 
                    },
                    });
                    
                    });
                }
            },
            {
              label: 'No',
              
            }
            ]
        })    
    }
    componentDidMount() {
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/predictionList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
              if(data.status === true){
                  this.setState({
                    users:data.data
                })
              }
        }.bind(this),
        statusCode: {
            401: function() {
                window.location.href="/admin/logout/"
            }
        },
        complete : function(){
            $('#example').DataTable();
        },
        error: function (xhr,ajaxOptions,throwError){
          },
        });
        });

        //Add level Icons
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/addlevelIcon',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                
                  this.setState({
                    algorithamIcon:data.data.algorithamIcon,
                    houseLevelIcon:data.data.houseLevelIcon
                })
              }
             
            
          }.bind(this),
          statusCode: {
            401: function() {
                window.location.href="/admin/logout/"
            }
        },
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
    }
    render() {
        let logined = true;
        const userData = localStorage.getItem("userData");
        
        if(userData === null){
            logined = false;
        }
        if(logined === false){
            return <Redirect to="/admin/login"></Redirect> 
        }
        const { users } = this.state; 
        
        return(
            <>
                <div className="content">
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6" md="12">
                                    <h5 className="head">Prediction List</h5>
                                   
                                </Col>
                                <Col lg="6" md="12">
                                    <div className="text-lg-right text-md-left add-main-btn">
                                        <Link to="./add-prediction">
                                            <button className="mb-3 blue-btn" data-target="#quoteForm" data-toggle="modal">Add Predictions</button>
                                        </Link>
                                        <button className="mb-3 blue-btn" data-target="#matchBackground" data-toggle="modal">Add Level Icon</button>
                                        <Export />
                                        <Import />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card className="table-view mt-4">
                        <CardBody>
                            <table id="example" className="display">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Teams</th>
                                        <th>Match Date</th>
                                        
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {users.map(({id,Tournament_name,team1_name,team2_name,datestart,flag}) => (
                                    <tr key={"k-"+id}>
                                        <td>{id}</td>
                                        <td>{Tournament_name}</td>
                                        <td className="team-list">
                                            <div className="d-inline-block align-middle mr-3">
                                               
                                                <div className="d-inline-block">
                                                    <span>{team1_name}</span>
                                                </div>
                                            </div>
                                            <div className="d-inline-block align-middle mr-3 vs">VS</div>
                                            <div className="d-inline-block align-middle mr-2">
                                               
                                                <div className="d-inline-block">
                                                    <span>{team2_name}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{datestart}</td>
                                         
                                        <td>
                                            <Link to={"/admin/edit-prediction/"+id}>
                                                <button className="edit-icon" type="button" data-target="#quoteForm" data-toggle="modal"><img src={Edit}/></button>
                                            </Link>
                                            
                                            <button onClick={() => this.deleteTeams(id)} className="edit-icon delete-icon"><img src={Delete}/></button>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>  
                        </CardBody>
                    </Card>
                </div>
                <div className="add-edit-team">
                <div className="modal fade" id="matchBackground" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <form className="add-stadium" onSubmit={this.updateLevelIcon}>
                            <input type="hidden" id="algorithamIcon" name="algorithamIcon"  />
                            
                                  <div className="col-12 p-0">
                                  <h6>Algoritham Level Icon</h6>
                                      <div className="position-relative">
                                          <img style={{ width: "100px",height:"100px" }} src={this.state.filealgorithamIcon !== null?this.state.filealgorithamIcon:this.state.algorithamIcon} className="stadium_image"/>
                                          <div className="upload-stadium-main">
                                              <input type="file" accept="image/png" id="falgorithamIcon" onChange={this.onChangealgorithamIcon} />
                                          </div>
                                      </div>
                                      {this.state.selectfalgo && (
                                          <div className="ml-3 text-center">
                                              <button onClick={this.resetFilealgorithamIcon} className="red-btn mt-2">Remove File</button>
                                          </div>
                                      )}
                                  </div>
                                  <input type="hidden" id="houseLevelIcon" name="houseLevelIcon"  />
                                  <div className="col-12 p-0">
                                  <h6>House Level Icon</h6>
                                      <div className="position-relative">
                                          <img style={{ width: "100px",height:"100px" }} src={this.state.filehouseLevelIcon !== null?this.state.filehouseLevelIcon:this.state.houseLevelIcon} className="stadium_image"/>
                                          <div className="upload-stadium-main">
                                              <input type="file" accept="image/png" id="fhouseLevelIcon" onChange={this.onChangehouseLevelIcon} />
                                             </div>
                                             
                                      </div>
                                      {this.state.selectfhouse && (
                                          <div className="ml-3 text-center">
                                              <button onClick={this.resetFilehouseLevelIcon} className="red-btn mt-2">Remove File</button>
                                          </div>
                                      )}
                                  </div>
                                  <div className="error-msg">Both Image size should be less than 1MB with square Image  like (150 X 150, 512 X  512) accept PNG only.</div>
                                          
                                  <div className="">
                                      <Row>
                                          
                                          
                                          
                                          <hr className="border-hr"/>
                                          <div className="col-12 text-right">
                                              <button type="submit" className="blue-btn">Add Level Icon</button>
                                              <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                                          </div>
                                      </Row>
                                  </div>
                              </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default prediction;