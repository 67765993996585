import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/custom.css";
import AdminLayout from "layouts/Admin.js";
import Login from "./views/Login.js";
window.$base_url = "https://admin.hiphopandhomeruns.com/API";
// window.$base_url = "https://thingproxy.freeboard.io/fetch/https://admin.hiphopandhomeruns.com/API";
const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
    <Route exact path="/admin/login" component={Login} />
      <Route  path="/admin" render={(props) => <AdminLayout {...props} />} />
      
      <Redirect to="/admin/logout" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
