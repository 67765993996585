import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import Add_match from "./../views/Add_match.js";
import Edit_match from "./../views/Edit_match.js";
import Userdetail from "./../views/User_detail.js";
import add_prediction from "./../views/Add_prediction.js";
import edit_prediction from "./../views/Edit_prediction.js";
import Profile from "./../views/Profile.js";
import matchlist from "./../views/Match_list.js";
import Editmatch from "./../views/Editmatch";
import viewMatch from "./../views/viewMatch";
import Advertise from "views/Advertise.js";
import { Redirect} from "react-router-dom";


var ps;
const Logout = () =>{
  localStorage.removeItem("userData");
  return <Redirect to="/admin/login"></Redirect>
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    };
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
            <Route path="/admin/logout" component={Logout} />
            <Route path="/admin/add_match/:id" component={Add_match} />
            <Route path="/admin/edit_match/:id" component={Editmatch} />
            <Route path="/admin/viewmatch/:id" component={viewMatch} />
            <Route path="/admin/edit_match/" component={Edit_match} />
            <Route path="/admin/User_detail/:id" component={Userdetail} />
            <Route path="/admin/add-prediction/" component={add_prediction} />
            <Route path="/admin/edit-prediction/:id" component={edit_prediction} />
            <Route path="/admin/profile/" component={Profile} />
            <Route path="/admin/matchlist/:id" component={matchlist} />
            {/* <Route path="/admin/advertise" component={Advertise} /> */}
            
            
          </Switch>
          <Footer fluid />
        </div>
        <div id="cover_spin" className=''></div>
       
      </div>
    );
  }
}

export default Dashboard;
