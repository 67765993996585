import React from "react";
import logo from "assets/img/nfl_logo.png";
import $ from 'jquery'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, Redirect} from "react-router-dom";
class Login extends React.Component{
    constructor(props){
        let logined = true;
        let error = false;
        super(props);
        const userData = localStorage.getItem("userData");
        if(userData == null){
            logined = false;
        }
        this.state ={
            email: "",
            password: "",
            logined,
            error
        };
        this.onChange = this.onChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }
    onChange(e){
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    formSubmit(e){
        e.preventDefault();
        
        
        
        
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/login',
              headers: {'App-Secret-Key': 'Mn2fKZG4M1170jDlVn6lOFTN6OE27f6UO99n9QDV',
                'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':'Basic YWRtaW46MTIzNA==',
                
                },
           
            data :{email : this.state.email, password:this.state.password},
              success : function(data) {
               
                if(data.status === true){
                    localStorage.setItem("appKey",JSON.stringify(data.rest_key));
                    localStorage.setItem("userData",JSON.stringify(data.data));
                    toast.success(data.msg);
                        this.setState({
                            logined:true
                        })
                  
                }else{
                    toast.error(data.msg);
                   
                }
            }.bind(this),
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
    }
   
    
    render(){
       
        if(this.state.logined){
            return <Redirect to="/admin/dashboard"></Redirect> 
        }
        let errorAlert;
        if(this.state.error){
            errorAlert= <div className="alert alert-danger alert-dismissible"><button type="button" className="close" data-dismiss="alert">&times;</button><strong>Error!</strong> Invalid Email Address Or Password.</div>;
        }
        return(
            <>
                <div className="MainDiv content">
                    <div className="account-pages my-5 py-sm-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="card overflow-hidden">
                                        <div className="bg-login text-center">
                                            <div className="bg-login-overlay"></div>
                                            <div className="position-relative">
                                                <h5 className="text-white welcome-back">Welcome Back!</h5>
                                                <p className="welcome-content">Sign in to contiune NFL.</p>
                                                <a href="#" className="logo logo-admin mt-2">
                                                    <img src={logo} width="70px"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="card-body pt-5">
                                            <div className="p-2">
                                            {errorAlert}
                                                <form className="login-main" onSubmit={this.formSubmit} method="post">
                                                    <div className="form-group" >
                                                        <label htmlFor="email">User Name</label>
                                                        <input type="email" className="form-control" 
                                                        onChange={this.onChange} value={this.state.email}  name="email" id="email" placeholder="Enter User Name  "/>
                                                    </div> 
                                                    <div className="form-group">
                                                        <label htmlFor="password">Password</label>
                                                        <input type="password" name="password" id="password" onChange={this.onChange} className="form-control" value={this.state.password} placeholder="Enter Password"/>
                                                    </div>
                                                    {/* <Link to={"/"}> */}
                                                        <button type="submit" className="mt-3 blue-btn" style={{ width: "100%"}}>SIGN IN</button>
                                                    {/* </Link> */}
                                                    <div className="text-center mt-4">
                                                        {/* <Link className="forgot" to={"forgot"}> */}
                                                            {/* <Icon name="lock-outline" size="large" fill="rgba(72,72,72,0.5)"/>&nbsp;&nbsp;Forgot Your Password? */}
                                                        {/* </Link> */}
                                                        {/* <p className="dont mt-3">Don't have an account? <Link to={"/signup"}>SIGNUP</Link></p> */}
                                                    </div>    
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default Login;