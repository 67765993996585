import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Patreon from "../assets/img/patreon.png";
import YouTube from "../assets/img/youtube.png";
import instagram from "../assets/img/instagram.svg";
import Twitter from "../assets/img/twitter.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Edit from "../assets/img/Edit.svg";
import Delete from "../assets/img/Delete.svg";
import upload_icon from "../assets/img/upload-icon.svg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let s_img = "";
class Social extends React.Component{
    
    constructor(props) {
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
        const users = JSON.parse(userData);
        user_id = users.id;
        }
    
        this.state = {
            user_id,
            appKey, 
            file: null , 
            users :[],
            links:"",
            s_id:""
            
        };
        this.textFieldChange = this.textFieldChange.bind(this);
        this.getSocialInfo = this.getSocialInfo.bind(this);
        this.socialUpdate = this.socialUpdate.bind(this);
      }
    textFieldChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
    }
    socialUpdate(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var social_link = $("#links").val();
        var social_id = $("#social_id").val();
      
       
        $.ajax({
          type: 'POST',
          url:  window.$base_url+'/v1/auth/updateSocialMedia',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
            },
          data :{user_id : this.state.user_id,social_link:social_link,social_id:social_id},
          success : function(data) {
            if(data.status === false){ 
                toast.success(data.msg); 
                $("#cover_spin").removeClass("cover-spin");
              window.location.reload(true);
            }
          }.bind(this),
              complete : function(){
          },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
          },
        });
      
      }
      getSocialInfo(id){
        $(()=>{
           
            var link = $("#link-"+id).text();
           
            this.setState({
                links:link,
                s_id:id
            })
        })
      }
      
    componentDidMount() {
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
        
        $.ajax({
            type: 'POST',
            url:window.$base_url+'/v1/auth/socialMedia',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
              if(data.status === true){
                  this.setState({
                    users:data.data
                })
              }
        }.bind(this),
        statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
            complete : function(){
                $('#example').DataTable();
            },
            error: function (xhr,ajaxOptions,throwError){
          },
        });
        });
    }
    render(){
        const { users } = this.state; 
       
        return(
            <>
                <div className="MainDiv content">
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6" md="12">
                                    <h5 className="head">Socials Link</h5>
                                    
                                </Col>
                                <Col lg="6" md="12">
                                 
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card className="mt-4">
                        <CardBody>
                            <table id="example" class="display">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Img</th>
                                        <th>Name</th>
                                        <th>Link</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {users.map(({id,social_name,social_link}) => (
                                    <tr>
                                        <td>{id}</td>
                                        <td><img src={social_name === "Patreon"?Patreon:(social_name === "Instagram"? instagram :(social_name === "YouTube"?YouTube:Twitter))} width="30px"/></td>
                                        <td>{social_name}</td>
                                        <td><spin id={"link-"+id}>{social_link}</spin></td>
                                        <td>
                                            <button className="edit-icon" onClick={() => this.getSocialInfo(id)} type="button" data-target="#edit-socialmedia" data-toggle="modal"><img src={Edit} /></button>
                                            
                                        </td>
                                    </tr>
                                ))} 
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
                <div className="add-edit-team">
                    <div className="modal fade" id="add-socialmedia" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content p-md-3" style={{ padding: "15px" }}>
                                <div className="modal-body p-0">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="upload_image">
                                                <img style={{ width: "100%" }} src={this.state.file} className="view_image"/>
                                                <div className="upload-icon-main">
                                                    <input type="file" className="upload-icon" onChange={this.onChange} />
                                                    <div className="custom_icon">
                                                    <img src={upload_icon}/>
                                                    </div>
                                                </div>
                                                {this.state.file && (
                                                    <div style={{ textAlign: "center" }}>
                                                    <button onClick={this.resetFile} className="red-btn mt-3">Remove File</button>
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label for="exampleInputEmail1">Enter Link</label>
                                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Link"/>
                                                </div>
                                            </div>
                                            <hr className="border-hr"/>
                                            <div className="col-12 text-right">
                                                <button type="button" className="blue-btn">Add Link</button>
                                                <button type="button" className="red-btn" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add-edit-team">
                    <div className="modal fade" id="edit-socialmedia" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content p-md-3" style={{ padding: "15px" }}>
                                <div className="modal-body p-0">
                                    <form onSubmit={this.socialUpdate} action="#">
                                    <input type="hidden" id="social_id" name="social_id" value={this.state.s_id} />
                                        <div className="row">
                                            <div className="col-12">
                                               
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                <label for="links">Enter Link</label>
                                                <input type="text" className="form-control" id="links" name="links"
                                                 value={this.state.links}
                                                 onChange={this.textFieldChange} 
                                                 />
                                                </div>
                                            </div>
                                            <hr className="border-hr"/>
                                            <div className="col-12 text-right">
                                                <button type="submit" className="blue-btn">Update Link</button>
                                                <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default Social;