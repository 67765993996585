import React from "react";
import $ from 'jquery'; 
import { render } from "react-dom";
import Row from "reactstrap/lib/Row";
import stateCity from "./../../../src/US_States_and_Cities.json";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const createBase64 = (image) =>{
    var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            //console.log(reader.result);
            var str = reader.result;
            str = str.replace("data:image/jpeg;base64,", "");
            
            str = str.replace("data:image/gif;base64,", "");
            str = str.replace("data:image/png;base64,", "");
            str = str.replace("data:image/jpg;base64,", "");
            $("#banner_img").val(str);
         return reader.result;
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }
    class Add_stadium extends React.Component{
        constructor(props) {
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        if(userData !== null){
        const users = JSON.parse(userData);
        user_id = users.id;
        }
        this.state = { 
            file: null, 
            name_add:"",
            image_src:null,
            user_id,
             appKey,
             state_add:"",
             city_add:""
            
        };
        this.onChange = this.onChange.bind(this);
        this.resetFile = this.resetFile.bind(this);
        //this.stateChange = this.stateChange.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.submitData = this.submitData.bind(this);
    }
    onChange(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            image_src : event.target.files[0],
            banner_img : createBase64(event.target.files[0])
        });
      
         
    }
    resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
        $("#banner_img").val("");
    }
    componentDidMount() {
        /*var stateSel = document.getElementById("state_add");
        //Load countries
        for (var states in stateCity) {
            stateSel.options[stateSel.options.length] = new Option(states, states);
        }*/
    }
    nameChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
    }
   /* stateChange (e) {	
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
        var citySel = document.getElementById("city_add");
        var stateSel = document.getElementById("state_add");
        citySel.length = 1; // remove all options bar first
       
        for (var city in stateCity[e.target.value]) {
            
           citySel.options[citySel.options.length] = new Option(stateCity[e.target.value][city], stateCity[e.target.value][city]);
        }
    }*/
    submitData(e){
       e.preventDefault();
       var name = $("#name_add").val();
       var banner = $("#banner_img").val();
       var state = $("#state_add").val();
       var city = $("#city_add").val();
       
       $.ajax({
        type: 'POST',
        url:  window.$base_url+'/v1/auth/addStadium',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
         },
        data :{user_id : this.state.user_id,name:name,state:state,city:city,banner:banner},
        success : function(data) {
          if(data.status === true){
            toast.success(data.msg); 
            window.location.reload(true);
        }
        }.bind(this),
        statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
            complete : function(){
        },
        error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
        },
    });
       
   }
    render(){
        return(
            <>
               <form onSubmit={this.submitData} className="add-stadium" encType="multipart/form-data">
                    <div className="col-12 p-0">
                        <input type="hidden" id="banner_img" name="banner_img"  />
                        <div className="position-relative">
                            <img style={{ width: "100%" }} src={this.state.file} className="stadium_image"/>
                            <div className="upload-stadium-main">
                                <input type="file" accept="image/*" onChange={this.onChange}/>
                            </div>
                        </div>
                        {this.state.file && (
                            <div className="ml-3 text-center">
                                <button onClick={this.resetFile} className="red-btn mt-2">Remove File</button>
                            </div>
                        )}
                    </div>
                    <div className="p-3">
                        <Row>
                            <div className="col-lg-12">
                                <div className="form-group">
                                <label htmlFor="name_add">Enter Stadium Name</label>
                                <input type="text" name="name_add" id="name_add" value={this.state.name} className="form-control"
                                onChange={this.nameChange} 
                                value={this.state.name_add} placeholder="Enter Stadium Name"
                                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                                />
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="state_add">State</label><br/>
                                    <input type="text"name="state_add" id="state_add"   className="form-control"
                                onChange={this.nameChange} 
                                value={this.state.state_add} placeholder="Enter State Name"/>
                                   
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="city_add">Enter City</label>
                                    <input type="text"name="city_add" id="city_add"   className="form-control"
                                onChange={this.nameChange} 
                                value={this.state.city_add} placeholder="Enter city Name"/>
                                   
                                </div>
                            </div>
                            <hr className="border-hr"/>
                            <div className="col-12 text-right">
                                <button type="submit"  className="blue-btn">Add Stadium</button>
                                <button type="button" className="red-btn" data-dismiss="modal">Cancel</button>
                            </div>
                        </Row>
                    </div>
               </form> 
               <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default Add_stadium;

