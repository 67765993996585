import React,{ Component } from "react";
import { Link, NavLink } from "react-router-dom";

import Profile from "assets/img/avatar.png";
import $ from 'jquery'; 
import {
  Row,
  Col,
  Table
} from "reactstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let monthsName = ["January", "February", "March", "April", "May", "June", "July",
"August", "September", "October", "November", "December"];
  export default class Userdetail extends Component{
    constructor(props){
      let user_id;
      const userData = localStorage.getItem("userData");
      let appKey = localStorage.getItem("appKey");
      appKey = appKey.replace(/['"]+/g, '');
      if(userData !== null){
        const users = JSON.parse(userData);
        user_id = users.id;
      }
      super(props);
      this.state ={
        user_id,
        appKey,
        uid :props.match.params.id,
        name:"",
        email:"",
        image:"1",
        plan_duration: "",
        months:"",
        current_plan_amount: "",
        start_date: "",
        end_date: "",
        total_spent: "",
        flag:"",
        by_admin:"",
        membership:"",
        profile_img:Profile
      };
      this.blockUnblock = this.blockUnblock.bind(this);
      this.subscribeUnsubscribe = this.subscribeUnsubscribe.bind(this);
  }
  subscribeUnsubscribe(){
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
     
      $.ajax({
          type: 'POST',
          url: window.$base_url+'/v1/auth/updateUserSubscriptionByAdmin',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
        },
          data :{user_id :this.state.user_id, id:this.state.uid},
          success : function(data) {
            if(data.status === true){
              $("#cover_spin").removeClass("cover-spin");
              toast.success(data.msg);
              window.location.reload(true);
              
            }
        }.bind(this),
        complete : function(){
         
        },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
        },
      });
    });
  }
  blockUnblock(){
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
     
      $.ajax({
          type: 'POST',
          url: window.$base_url+'/v1/auth/userBlock',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
        },
          data :{user_id :this.state.user_id, id:this.state.uid},
          success : function(data) {
            if(data.status === true){
              toast.success(data.msg);
              $("#cover_spin").removeClass("cover-spin");
              window.location.reload(true);
              
            }
        }.bind(this),
        complete : function(){
         
        },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
        },
      });
    });
  }
  componentDidMount() {
    
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
          
      $.ajax({
          type: 'POST',
          url: window.$base_url+'/v1/auth/userDetails',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
        },
          data :{user_id :this.state.user_id, id:this.state.uid},
          success : function(data) {
            $("#cover_spin").removeClass("cover-spin");
            if(data.status === true){
              let str_date = "";
              let end_date = "";
              if(data.data.start_date !== ""){
                let date = new Date(data.data.start_date);
                let day = date.getDate();
                let fullmonth = monthsName[date.getMonth()];
                let Year = date.getFullYear();
                str_date = day+" "+ fullmonth +" "+ Year;
               
              }
              if(data.data.end_date !== ""){
                let date = new Date(new Date(data.data.end_date).setDate(new Date(data.data.end_date).getDate() + 1));
                let day = date.getDate();
                let fullmonth = monthsName[date.getMonth()];
                let Year = date.getFullYear();
                end_date = day+" "+ fullmonth +" "+ Year;
               
              }
                this.setState({
                  users:data.data,
                  name:data.data.name,
                  email:data.data.email,
                  image:data.data.profile_img,
                  plan_duration:data.data.plan_duration,
                  months:data.data.months,
                  current_plan_amount:data.data.current_plan_amount,
                  start_date:str_date,
                  end_date:end_date,
                  total_spent:data.data.total_spent,
                  flag:data.data.flag,
                  by_admin:data.data.by_admin,
                  membership:data.data.membership,
                  
              })
              if(data.data.profile_img != ""){
                this.setState({
                  profile_img : data.data.profile_img
                }) 
              }
            }
        }.bind(this),
        statusCode: {
          401: function() {
            window.location.href="/admin/logout/"
          }
        },
        complete : function(){
          $('#example').DataTable();
        },
          error: function (xhr,ajaxOptions,throwError){
          //Error block 
        },
      });
    });
  }
  render(){
    console.log(this.state.by_admin);
    let subbtn = '';
    if(this.state.by_admin != 0){
      subbtn = <button type="button" onClick={this.subscribeUnsubscribe} className="blue-btn">{this.state.membership === "1"?"Unsubscribe":"Subscribe"}</button>;
    }else{
      subbtn = '';
    }
    return(
      <>
        <div className="MainDiv content">
          <Row>
            <Col lg="12">
              <h5 className="head">User Detail</h5>
             
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="4">
              <div className="user_profile_main">
                <form>
                  <div className="text-center mb-4">
                    <img src={this.state.profile_img} width="50%"/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" value={this.state.name} readOnly/>
                  </div>
                  <div>
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" value={this.state.email} readOnly/>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg="6">
              <div className="subscription_detail user_profile_main">
                <Row>
                  <Col lg="6" className="mb-4">
                    <h5 className="m-0">Plan Duration</h5>
                    <p>{this.state.months !== "" ?(this.state.months !== 0? this.state.plan_duration+ " Month":"Weekly"):""}</p>
                  </Col>
                  <Col lg="6" className="mb-4">
                    <h5 className="m-0">Current Plan Amount</h5>
                    <p>$ {this.state.current_plan_amount}</p>
                  </Col>
                  <Col lg="6" className="mb-4">
                    <h5 className="m-0">Start Date</h5>
                    <p>{this.state.start_date}</p>
                  </Col>
                  <Col lg="6" className="mb-4">
                    <h5 className="m-0">Renewal Date</h5>
                    <p>{this.state.end_date}</p>
                  </Col>
                 
                  <Col lg="6" className="mb-4">
                    <h5 className="m-0">Total Spent</h5>
                    <p>$ {this.state.total_spent}</p>
                  </Col>
                  <Col lg="12">
                  <div className="text-right">
                  {/*subbtn*/}
                      <button type="button" onClick={this.blockUnblock} className="blue-btn">{this.state.flag === "1"?"Block":"Unblock"}</button>
                    </div>
                    
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </div>
        <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
      </>
    );
  }
}


