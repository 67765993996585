import React from "react";
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import { Card,  CardBody, Row, Col } from "reactstrap";
import Edit from "../assets/img/Edit.svg";
import Delete from "../assets/img/Delete.svg";
import upload_icon from "../assets/img/upload-icon.svg";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
const createBase64 = (image) =>{
  var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
          //console.log(reader.result);
          var str = reader.result;
          str = str.replace("data:image/jpeg;base64,", "");
          str = str.replace("data:image/gif;base64,", "");
            str = str.replace("data:image/png;base64,", "");
            str = str.replace("data:image/jpg;base64,", "");
          $("#banner_img").val(str);
          $("#banner_edit").val(str);
       return reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
  }
class teamlist extends React.Component {
  
 
  constructor(props){
    
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    this.state = {
      file: null, 
      user_id,
      appKey,
      add_short:"",
      add_name:"",
      users :[],
      edit_tname:"",
      edit_tshort:"",
      team_profile :null,
      team_id:"",
      selectf:null
    }
    this.onChange = this.onChange.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.textFieldChange = this.textFieldChange.bind(this);
    this.AddfrmSubmit = this.AddfrmSubmit.bind(this);
    this.deleteTeams = this.deleteTeams.bind(this);
    this.dgetTeamsInfo = this.dgetTeamsInfo.bind(this);
    this.teamUpdate = this.teamUpdate.bind(this);
    this.reactiveTeams = this.reactiveTeams.bind(this)
  }
  textFieldChange(e){
    this.setState({
        [e.target.name] : e.target.value,
        
    })	 
}
teamUpdate(e){
  e.preventDefault();
  $("#cover_spin").addClass("cover-spin");
  var name = $("#edit_tname").val();
  var team_profile = $("#banner_edit").val();
  var short_name = $("#edit_tshort").val();
  var team_id = $("#teamId_edit").val();
  
  $.ajax({
    type: 'POST',
    url:  window.$base_url+'/v1/auth/updateTeam',
    headers: {'App-Secret-Key': this.state.appKey,
    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization':'Basic YWRtaW46MTIzNA=='
      },
    data :{user_id : this.state.user_id,name:name,short_name:short_name,team_profile:team_profile,team_id:team_id},
    success : function(data) {
      if(data.status === true){
        toast.success(data.msg); 
        $("#cover_spin").removeClass("cover-spin");
        window.location.reload(true);
      }
    }.bind(this),
        complete : function(){
    },
    error: function (xhr,ajaxOptions,throwError){
      $("#cover_spin").removeClass("cover-spin");
      toast.error(xhr.responseJSON.msg);
    },
  });

}
dgetTeamsInfo(id){
  
      $(()=>{
        $("#cover_spin").addClass("cover-spin");
        
      $.ajax({
          type: 'POST',
          url: window.$base_url+'/v1/auth/teamDetails',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
        },
          data :{user_id : this.state.user_id,team_id:id},
          success : function(data) {
            if(data.status === true){
              $("#cover_spin").removeClass("cover-spin");
              this.setState({
                edit_tname : data.data.name,
                edit_tshort : data.data.short_name,
                file : data.data.team_profile,
                team_id : data.data.id,
              })
            }
          
          
        }.bind(this),
        complete : function(){
        
        },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
        },
      });
        
      });
    
}
deleteTeams(id){
  confirmAlert({
    title: '',
    message: 'Are you sure want to delete?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
      
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/deleteTeam',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id,team_id:id},
        success : function(data) {
          $("#cover_spin").removeClass("cover-spin");
          if(data.status === true){
            toast.success(data.msg);
            
            window.location.reload(true);
            this.setState({
                
            })
          }else{
            toast.error(data.msg);
          }
        
        
      }.bind(this),
      complete : function(){
      
      },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
          toast.error(xhr.responseJSON.msg);
      },
    });
      
    });
  }
},
{
  label: 'No',
  
}
]
})
}
reactiveTeams(id){
  confirmAlert({
    title: '',
    message: 'Are you sure want to Active?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
      
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/deleteTeam',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id,team_id:id},
        success : function(data) {
          if(data.status === true){
            toast.success(data.msg);
            $("#cover_spin").removeClass("cover-spin");
            window.location.reload(true);
            this.setState({
                
            })
          }
        
        
      }.bind(this),
      complete : function(){
      
      },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
          toast.error(xhr.responseJSON.msg);
      },
    });
      
    });
  }
},
{
  label: 'No',
  
}
]
})
}
onChange(event) {
  this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      image_src : event.target.files[0],
      banner_img : createBase64(event.target.files[0]),
      selectf:true
  });
}
AddfrmSubmit(e){
  e.preventDefault();
  $("#cover_spin").addClass("cover-spin");
       var name = $("#add_name").val();
       var team_profile = $("#banner_img").val();
       var short_name = $("#add_short").val();
      
      
       $.ajax({
        type: 'POST',
        url:  window.$base_url+'/v1/auth/addTeam',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
         },
        data :{user_id : this.state.user_id,name:name,short_name:short_name,team_profile:team_profile},
        success : function(data) {
          $("#cover_spin").removeClass("cover-spin");
          if(data.status === true){
            toast.success(data.msg);
            window.location.reload(true);
          }
          else{
            toast.error(data.msg);
          }
        }.bind(this),
            complete : function(){
        },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
        },
    });

}
resetFile(event) {
  
    event.preventDefault();
    this.setState({ file: null,selectf:null });
    $("#banner_img").val("");
}
  componentDidMount() {
  
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
     
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/teamList',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id},
        success : function(data) {
          $("#cover_spin").removeClass("cover-spin");
          if(data.status === true){
           
              this.setState({
                users:data.data
            })
          }
         
        
      }.bind(this),
      statusCode: {
        401: function() {
          window.location.href="/admin/logout/"
        }
      },
      complete : function(){
        $('#example').DataTable();
      },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
         
      },
    });
       
    });
  }
  render() {
    let logined = true;
    const userData = localStorage.getItem("userData");
    
          if(userData === null){
              logined = false;
          }
          if(logined === false){
            return <Redirect to="/admin/login"></Redirect> 
         }
         const { users } = this.state;
         
    return (
      
     
      <>
        <div className="MainDiv content">
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="6" md="12">
                  <h5 className="head">Team List</h5>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Team List</li>
                    </ol>
                  </nav>
                </Col>
                <Col lg="6" md="12">
                  <div className="text-lg-right text-md-left add-main-btn">
                    <button className="add-btn" data-target="#add-team" data-toggle="modal">Add Team</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Card className="table-view mt-4">
            <CardBody>
              <table id="example" className="display">
                <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Short Name</th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {users.map(({id,name,team_profile,short_name,flag}) => (
                  <tr>
                    <td>{id}</td>
                    <td>{name}</td>
                    <td>{short_name}</td>
                    <td> <img className="img-40 mr-2 rounded-circle align-top" style={{width:'50px',height:'50px'}} src={team_profile} alt={name}/></td>
                    <td>
                      <button onClick={() => this.dgetTeamsInfo(id)} className="edit-icon" type="button" data-target="#quoteForm" data-toggle="modal"><img src={Edit}/></button>
                      
                      {flag == 1 ? (
                          <button onClick={() => this.deleteTeams(id)} className="edit-icon delete-icon"><img src={Delete}/></button>
                        ) : (
                          <button onClick={() => this.reactiveTeams(id)} className="edit-icon delete-icon btn-info">Reactive</button>
                    
                        )}
                      
                      
                     </td>
                  </tr>
                    ))}
                
                 
                </tbody>
              </table>  
            </CardBody>
          </Card>
        </div>
        <div className="add-edit-team">
          <div className="modal fade" id="quoteForm" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content p-md-3">
                <div className="modal-body p-0">
                  <form onSubmit={this.teamUpdate} action="#">
                  <input type="hidden" id="banner_edit" name="banner_edit"  />
                  <input type="hidden" id="teamId_edit" name="teamId_edit" value={this.state.team_id}  />
                    <div className="row">
                      <div className="col-12">
                        <div className="upload_image">
                          <img style={{ width: "100%" }} src={this.state.file} className="view_image"/>
                          <div className="upload-icon-main">
                            <input type="file" className="upload-icon" accept="image/*" onChange={this.onChange} />
                            <div className="custom_icon">
                              <img src={upload_icon}/>
                            </div>
                          </div>
                          {this.state.selectf && (
                            <div style={{ textAlign: "center" }}>
                              <button onClick={this.resetFile} className="red-btn mt-3">Remove File</button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="edit_tname">Enter Team Name</label>
                          <input type="text" className="form-control" id="edit_tname" name="edit_tname"  value={this.state.edit_tname}
                            onChange={this.textFieldChange} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="edit_tshort">Enter Short Name</label>
                          <input type="text" className="form-control" id="edit_tshort"  name="edit_tshort"
                          value={this.state.edit_tshort}
                          onChange={this.textFieldChange}
                          />
                        </div>
                      </div>
                      <hr className="border-hr"/>
                      <div className="col-12 text-right">
                        <button type="submit" className="blue-btn">Update Team</button>
                        <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-edit-team">
          <div className="modal fade" id="add-team" tabindex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content p-md-3">
                <div className="modal-body p-0">
                  <form id="addTeamFrm" onSubmit={this.AddfrmSubmit}>
                  <input type="hidden" id="banner_img" name="banner_img"  />
                    <div className="row">
                      <div className="col-12">
                        <div className="upload_image">
                          <img style={{ width: "100%" }} src={this.state.file} className="view_image"/>
                          <div className="upload-icon-main">
                            <input type="file" className="upload-icon" accept="image/*" onChange={this.onChange} required />
                            <div className="custom_icon">
                              <img src={upload_icon}/>
                            </div>
                          </div>
                          {this.state.file && (
                            <div style={{ textAlign: "center" }}>
                              <button onClick={this.resetFile} className="red-btn mt-3">Remove File</button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="add_name">Enter Team Name</label>
                          <input type="text" className="form-control" id="add_name" name="add_name" 
                          value={this.state.add_name}
                          onChange={this.textFieldChange} required/>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="add_short">Enter Short Name</label>
                          <input type="text" className="form-control" id="add_short" name="add_short" 
                          value={this.state.add_short}
                          onChange={this.textFieldChange} required />
                        </div>
                      </div>
                      <hr className="border-hr"/>
                      <div className="col-12 text-right">
                        <button type="submit" className="blue-btn">Add Team</button>
                        <button type="reset" className="red-btn" data-dismiss="modal">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
      </>
    );
  }
}

export default teamlist;
