import React from "react";
import {Redirect } from "react-router-dom";
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

import DatePicker from "react-datepicker";
import {
    Card,
    CardBody,
    Row,
    Col,
    
} from "reactstrap";

class Editmatch extends React.Component{
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        
        this.state = {
            t_id: props.match.params.id,  
            user_id,
            appKey,
            no_users:0,
            no_matches:0,
            no_teams:0,
            tournaments :[],
            teams:[],
            stadiums:[],
            tournamentName : "",
            tournamentStart : "",
            tournamentEnd : "",
            tournament_id : "",
            matchtournament:"",
            matchStadium:"",
            matchTeam1_id:"",
            matchTeam2_id:"",
            matchDate:new Date(),
            matchTime:"",
            date: ""
        }
        this.getdetailstourna = this.getdetailstourna.bind(this);
        this.matchFrmsubmit = this.matchFrmsubmit.bind(this);
        this.textFieldChange = this.textFieldChange.bind(this);
        this.dateChanged = this.dateChanged.bind(this);
        this.matchChanged = this.matchChanged.bind(this);
        
      }
      textFieldChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
    }
    matchChanged(id){
      var team1_id = $('#team_1 option:selected').val();
      alert(team1_id);
      $('#team_2 option[value="'+id+'"]').prop('disabled','disabled');
    }
    dateChanged(d){
      this.setState({date: d});
    }
      matchFrmsubmit(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var tournament_id = $('#tournament option:selected').val();
        var team1_id = $('#team_1 option:selected').val();
        var team2_id = $('#team_2 option:selected').val();
        var stadium_id = $('#stadium option:selected').val();
        var mdate = $('#matchDate').val();
        var mtime = $('#matchTime').val();
        var match_id = $('#match_id').val();
        var start_date = mdate+" "+mtime+":00";
       
        $.ajax({
          type: 'POST',
          url:  window.$base_url+'/v1/auth/updateTournamentMatch',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
            },
          data :{
                user_id : this.state.user_id,
                tournament_id:tournament_id,
                team1_id:team1_id,
                team2_id:team2_id,
                stadium_id:stadium_id,
                start_date:start_date,
                match_id:match_id

              },
          success : function(data) {
            
            if(data.status === true){ 
              toast.success(data.msg); 
              
                window.location.href="/admin/matchlist/"+$('#tournament option:selected').val();
            }else{
              toast.error(data.msg); 
            }
          },
          complete : function(){
            $("#cover_spin").removeClass("cover-spin");
          },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
          },
        });

      }
      getdetailstourna(id){
         
        $(()=>{
          var id = $('#tournament option:selected').val();
           
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/tournamentDetails',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id,tournament_id:id},
              success : function(data) {
                if(data.status === true){
                  
                  this.setState({
                    tournamentName : data.data.name,
                    tournamentStart : data.data.start_date,
                    tournamentEnd : data.data.end_date,
                    tournament_id : data.data.id,
                  })
                }
               
              
            }.bind(this),
            complete : function(){
             
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
             
          });
      }
      componentDidMount(){
        $("#cover_spin").addClass("cover-spin");
        $(()=>{
          
          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                $("#cover_spin").removeClass("cover-spin");
                  this.setState({
                    tournaments:data.data,
                    
                })
                
              }
          }.bind(this),
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });

          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentMatchDetails',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id,match_id:this.state.t_id},
            success : function(data) {
              if(data.status === true){
                 var start_date = data.data.start_date;
                 var today = new Date(start_date);
                 var dd = today.getDate();
                 var mm = today.getMonth()+1; //As January is 0.
                 var yyyy = today.getFullYear();

                  
                    if(dd<10) dd='0'+dd;
                    if(mm<10) mm='0'+mm;
                    const curdate = yyyy+'-'+mm+'-'+dd;
                    var hr = today.getHours(); 
                    var mn = today.getMinutes(); 
                    if(hr<10) hr='0'+hr;
                    if(mn<10) mn='0'+mn;
                     var time = hr+":"+ mn+":00";
                  this.setState({
                    matchtournament:data.data.tournament_id,
                    matchStadium:data.data.stadium_id,
                    matchTeam1_id:data.data.team1_id,
                    matchTeam2_id:data.data.team2_id,
                    matchDate:curdate,
                    matchTime:time
                    
                })
                console.log(curdate);
                $.ajax({
                  type: 'POST',
                  url:window.$base_url+'/v1/auth/tournamentDetails',
                  headers: {'App-Secret-Key': this.state.appKey,
                  'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Authorization':'Basic YWRtaW46MTIzNA=='
                },
                  data :{user_id : this.state.user_id,tournament_id:data.data.tournament_id},
                  success : function(data) {
                    if(data.status === true){
                      
                      this.setState({
                        tournamentName : data.data.name,
                        tournamentStart : data.data.start_date,
                        tournamentEnd : data.data.end_date,
                        tournament_id : data.data.id,
                      })
                    }
                   
                  
                }.bind(this),
                complete : function(){
                 
                },
                  error: function (xhr,ajaxOptions,throwError){
                  //Error block 
                },
              });
              }
          }.bind(this),
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });


          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/teamList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
               
                  this.setState({
                    teams:data.data
                })
              }
             
            
          }.bind(this),
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/stadiumList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                  this.setState({
                    stadiums:data.data,
                   
                })
              }
            }.bind(this),
            complete : function(){
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });

        });
      }
    render(){
        let logined = true;
        const userData = localStorage.getItem("userData");
  
        if(userData === null){
            logined = false;
        }
        if(logined === false){
          return <Redirect to="/admin/login"></Redirect> 
       }
       const { tournaments,teams,stadiums} = this.state;
      
        return(
            <div className="MainDiv content">
                <Row className="align-items-center">
                    <Col lg="12">
                        <h5 className="head">Match Details</h5>
                       
                    </Col>
                </Row>
                <Card className="mt-4">
                    <CardBody>
                        <form>
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label htmlFor="tournament" className="mb-2">Select Tournament</label><br/>
                                        <select name="tournament" id="tournament" onChange={() => this.getdetailstourna(this.value)} className="selectbar form-select" required>
                                            <option value="" >Select Tournaments</option>
                                            {tournaments.map(({id,name}) => (
                                            <option value={id}  selected={this.state.matchtournament == id ?true:false}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <Row className="mt-2">
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Tournament Name</h5>
                                    <p>{this.state.tournamentName}</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Start Date</h5>
                                    <p>{this.state.tournamentStart}</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>End Date</h5>
                                    <p>{this.state.tournamentEnd}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr className="my-4"/>
                        <form onSubmit={this.matchFrmsubmit}>
                        <input type="hidden" id="match_id" name="match_id" value={this.state.t_id}  />
                                 
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 1</label>
                                        <select className="selectbar" name="team_1" id="team_1" onChange={() => this.matchChanged(this.value)} required>
                                            <option value="">Select Team</option>
                                            {teams.map(({id,name}) => (
                                            
                                            <option value={id} selected={this.state.matchTeam1_id == id ?true:false}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 2</label>
                                        <select className="selectbar" name="team_2" id="team_2" required>
                                        <option value="">Select Team</option>
                                            {teams.map(({id,name}) => (
                                                <option value={id} selected={this.state.matchTeam2_id == id ?true:false}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Date</label>
                                        
                                        <DatePicker 
                                        selected={new Date(this.state.matchDate)}
                                        onChange={this.dateChanged} 
                                        name="matchDate"
                                        className="form-control"
                                        id="matchDate"
                                        dateFormat="yyyy-MM-dd"
                                        minDate={new Date(this.state.tournamentStart)}
                                        maxDate={new Date(this.state.tournamentEnd)} 
                                        />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Time</label>
                                        <input type="time" value={this.state.matchTime}  className="form-control" name="matchTime" id="matchTime" onChange={this.textFieldChange} required />
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Stadium</label>
                                        <select className="selectbar form-select" name="stadium" id="stadium" required>
                                            <option value="" >Select Stadium</option>
                                            {stadiums.map(({id,name}) => (
                                                <option value={id} selected={this.state.matchStadium == id ?true:false}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="12" className="mt-2 text-right">
                                   
                                   

                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                  />
            </div>
        );
    }
}

export default Editmatch;