import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from 'jquery'; 
import Eye from "../assets/img/Eye.svg";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons-bs';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import {
  Button,
  Card,
  CardBody,Row,
  Col,
  Table
 
} from "reactstrap";

 
class User extends React.Component {
  constructor(props){
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    super(props);
    this.state = {
      user_id,
      appKey,
      users :[]
    }
  }
  componentDidMount() {
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
      
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/userList',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id},
        success : function(data) {
          if(data.status === true){
            $("#cover_spin").removeClass("cover-spin");
              this.setState({
                users:data.data
            })
          }
      }.bind(this),
      statusCode: {
        401: function() {
          window.location.href="/admin/logout/"
        }
      },
        complete : function(){
          $('#example').DataTable({
            "lengthChange": true,
              "dom": 'Bfrtip',
              buttons: [
                { extend: 'csv', text: 'Export to CSV' }
            ]
        } );
        },
          error: function (xhr,ajaxOptions,throwError){
          //Error block 
      },
      });
      });
    }
  render(){
    let logined = true;
    const userData = localStorage.getItem("userData");
    
          if(userData === null){
              logined = false;
          }
          if(logined === false){
            return <Redirect to="/admin/login"></Redirect> 
         }
    const { users } = this.state; 
   
    return (
      <>
        <div className="MainDiv content">
          <Row>
            <Col lg="12">
              <h5 className="head">User List</h5>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                  <li className="breadcrumb-item active" aria-current="page">User List</li>
                </ol>
              </nav>
            </Col>
          </Row>
          <Card className="table-view mt-4">
            <CardBody>
              <table id="example" className="display">
                <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Joining Date</th>
                      <th>Subscription</th>
                      <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {users.map(({id,name,email,membership,created_at,flag}) => (
                  <tr key={"userkey_"+id}>
                  <td>{id}</td>
                  <td>{name}</td>
                  <td>{email}</td>
                  <td>{created_at}</td>
                  <td>{membership == 1?"Paid":"Free"}</td>
                  <td>
                    <Link to={"./User_detail/"+id}>
                      <button className="edit-icon"><img src={Eye}/></button>
                    </Link>
                   
                  </td>
                </tr>
                ))}
                 
                   
                </tbody>
              </table>  
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default User;
