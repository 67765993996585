import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Profile1 from "assets/img/Profile.svg";
import upload_icon from "../assets/img/upload-icon.svg";
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import dataTablesDataTables from "datatables.net-dt";
const createBase64 = (image) =>{
    var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = function () {
            //console.log(reader.result);
            var str = reader.result;
            str = str.replace("data:image/jpeg;base64,", "");
            $("#banner_img").val(str);
         return reader.result;
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
    }
class Profile extends React.Component{
    constructor(props) {
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.state = { 
            file: null,
            user_id,
            appKey,
            name:"",
            email:"",
            current_password:"",
            new_password:"",
            confirm_password:"",
            profile_img:null 
        };
        this.onChange = this.onChange.bind(this);
        this.resetFile = this.resetFile.bind(this);
        this.profileUpdate = this.profileUpdate.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.passwordUpdate = this.passwordUpdate.bind(this);
    }
    nameChange(e){
        this.setState({
            [e.target.name] : e.target.value,
            
        })	 
    }
    passwordUpdate(e){
        e.preventDefault();
        var form = $("#frmPassword");
        var formdata = form.serializeArray();
      
        $("#cover_spin").addClass("cover-spin");
        $.ajax({
            type: 'POST',
            url:  window.$base_url+'/v1/auth/changePassword',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
             },
            data :formdata,
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
              if(data.status === true){
                toast.success(data.msg); 
                    this.setState({
                        current_password:"",
                        new_password:"",
                        confirm_password:"",
                    
                    })	 
                }else{
                    toast.error(data.msg); 
                }
            }.bind(this),
            statusCode: {
                401: function() {
                  //window.location.href="/admin/logout/"
                }
              },
            complete : function(){
                
            }.bind(this),
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
                toast.error(xhr.responseJSON.msg);
            },
        });
       
    }
    profileUpdate(e){
        e.preventDefault();
        var name = $("#name").val();
        var profile_img = $("#banner_img").val();
        var email = $("#email").val();
        $("#cover_spin").addClass("cover-spin");
        $.ajax({
            type: 'POST',
            url:  window.$base_url+'/v1/auth/updateProfile',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
             },
            data :{user_id : this.state.user_id,name:name,email:email,profile_img:profile_img},
            success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
              if(data.status === true){
                toast.success(data.msg); 
                //window.location.reload(true);
                }else{
                    toast.error(data.msg); 
                }
            }.bind(this),
            statusCode: {
                401: function() {
                  window.location.href="/admin/logout/"
                }
              },
                complete : function(){
            },
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
                toast.error(xhr.responseJSON.msg);
            },
        });
       
    }
    onChange(event) {
        this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            profile_img : createBase64(event.target.files[0]),
            profile_img: URL.createObjectURL(event.target.files[0]),
        });
    }
    resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
        $("#banner_img").val("");
    }
    componentDidMount() {
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
           
          $.ajax({
              type: 'POST',
              url:window.$base_url+'/v1/auth/profile',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id},
              success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
                if(data.status === true){
                 
                    this.setState({
                      
                      name:data.data.name,
                      email:data.data.email,
                      profile_img:data.data.profile_img
                  })
                }
              }.bind(this),
              statusCode: {
                401: function() {
                  window.location.href="/admin/logout/"
                }
              },
              complete : function(){
                $("#cover_spin").removeClass("cover-spin");
            },
              error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
            },
          });
             
          });
    }
    render(){
        return(
            <>
                <div className="MainDiv content">
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6">
                                    <h5 className="head">Profile</h5>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Card className="mt-4">
                        <CardBody>
                            <Row>
                                <Col lg="4">
                                    <form onSubmit={this.profileUpdate} className="profile-form">
                                    <input type="hidden" id="banner_img" name="banner_img"  />
                                        <Col lg="12">
                                            <div className="upload_image mt-3">
                                                <img style={{ width: "100%" }} src={this.state.profile_img !==""?this.state.profile_img:this.state.file} className="view_image"/>
                                                <div className="upload-icon-main">
                                                    <input type="file" className="upload-icon" accept="image/*" onChange={this.onChange} />
                                                    <div className="custom_icon custom_icon1">
                                                    <img src={upload_icon}/>
                                                    </div>
                                                </div>
                                                {this.state.file && (
                                                    <div style={{ textAlign: "center" }}>
                                                    <button onClick={this.resetFile} className="red-btn mt-3">Remove File</button>
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Name</label>
                                                <input type="text" name="name" id="name"  onChange={this.nameChange} className="form-control" value={this.state.name} />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Email</label>
                                                <input type="email" name="email"  onChange={this.nameChange} id="email" className="form-control" value={this.state.email}/>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <button type="submit" style={{ width: "100%" }} className="blue-btn mb-3">UPDATE PROFILE</button>
                                        </Col>
                                    </form>
                                </Col>
                                <Col lg="7" className="mt-md-3 mt-sm-3 mt-xs-3 change">
                                    <form onSubmit={this.passwordUpdate} id="frmPassword" className="profile-form">
                                    <input type="hidden" id="user_id" name="user_id" value={this.state.user_id} />
                                        <div className="col-12 mt-2 mb-3">
                                            <h5 className="change-password">Change Password</h5>  
                                        </div>         
                                        <div className="col-12">
                                            <div className="form-group">
                                            <label>Current Password</label>
                                            <input type="password" name="current_password" className="form-control" onChange={this.nameChange} value={this.state.current_password} placeholder="Current Password"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Enter New Password</label>
                                            <input type="password" name="new_password" className="form-control" onChange={this.nameChange} value={this.state.new_password} placeholder="Enter New Password"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Confirm New Password</label>
                                            <input type="password" className="form-control" name="confirm_password" onChange={this.nameChange}  value={this.state.confirm_password} placeholder="Confirm New Password"/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" style={{ width: "100%" }} className="blue-btn mb-3">CHANGE PASSWORD</button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
                <ToastContainer
              position="top-right"
             
            />
            </>
        );
    }
}

export default Profile;