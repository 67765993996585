import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import $ from 'jquery';
import { read, utils, writeFile } from 'xlsx';
class Export extends React.Component {
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.state = {
            user_id,
            appKey,
            users :[],
            exportData:"",
            

        }
    }
    componentDidMount(){
        $(()=>{
            $.ajax({
                type: 'POST',
                url: window.$base_url+'/v1/auth/export',
                headers: {'App-Secret-Key': this.state.appKey,
                'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                'Authorization':'Basic YWRtaW46MTIzNA==',
                
            },
              data :{user_id : this.state.user_id},
              success : function(data) {
                this.setState({
                    exportData:data
                })
               
            }.bind(this),
            statusCode: {
                401: function() {
                  window.location.href="/admin/logout/"
                }
              },
              complete : function(){
                
              },
                error: function (xhr,ajaxOptions,throwError){
                //Error block 
              },
            });
        })
    }
   
  render() {
    const { exportData } = this.state; 
  
    const handleExport = () => {
        const headings = [[
            'ID',
            'TOURNAMENT_ID',
            'MATCH_ID',
            'ALG_LEVEL',
            'ALG_LEVEL_TEAM',
            'ALG_LINE',
            'ALG_LINE_TEAM',
            'ALG_DESC',
            'TEAM1_DESC',
            'TEAM2_DESC',
            'POINT_DESC',
            'HOUSE_LEVEL',
            'HOUSE_LEVEL_TEAM',
            'HOUSE_LINE',
            'HOUSE_LINE_TEAM',
            'HOUSE_DESC',
            'PAID',
            'SPORTBOOK_1',
            'TEAM_ID_1',
            'MONDAY_1',
            'WEDNESDAY_1',
            'FRIDAY_1',
            'SPORTBOOK_2',
            'TEAM_ID_2',
            'MONDAY_2',
            'WEDNESDAY_2',
            'FRIDAY_2',
            'SPORTBOOK_3',
            'TEAM_ID_3',
            'MONDAY_3',
            'WEDNESDAY_3',
            'FRIDAY_3',
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        exportData.map((item, index)=>{
          utils.sheet_add_aoa(ws, [[item?.id]], {origin: `A${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.tournament_id]], {origin: `B${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.match_id]], {origin: `C${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.algorithm_level]], {origin: `D${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.algorithm_level_team]], {origin: `E${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.algorithm_line]], {origin: `F${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.algorithm_line_team]], {origin: `G${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.algorithm_desc]], {origin: `H${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.team1_desc]], {origin: `I${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.team2_desc]], {origin: `J${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.point_table_desc]], {origin: `K${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.house_level]], {origin: `L${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.house_level_team]], {origin: `M${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.house_line]], {origin: `N${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.house_line_team]], {origin: `O${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.house_desc]], {origin: `P${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.isPaid]], {origin: `Q${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.sportBook_1]], {origin: `R${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.team_id_1]], {origin: `S${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.monday_1]], {origin: `T${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.wednesday_1]], {origin: `U${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.friday_1]], {origin: `V${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.sportBook_2]], {origin: `W${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.team_id_2]], {origin: `X${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.monday_2]], {origin: `Y${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.wednesday_2]], {origin: `Z${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.friday_2]], {origin: `AA${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.sportBook_3]], {origin: `AB${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.team_id_3]], {origin: `AC${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.monday_3]], {origin: `AD${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.wednesday_3]], {origin: `AE${2+index}`});
          utils.sheet_add_aoa(ws, [[item?.friday_3]], {origin: `AF${2+index}`});
        });
       
        
       utils.book_append_sheet(wb, ws, 'Data');
        writeFile(wb, 'predictions.xlsx');
    }
    return (
      <>
       <button className="mb-3 blue-btn" onClick={handleExport} >Export</button>
      </>
    );
  }
}



export default Export;