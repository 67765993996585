import React from "react";
import { Link, NavLink,Redirect } from "react-router-dom";
import $ from 'jquery'; 

import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,ContentState, convertFromHTML } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import draftToHtml from 'draftjs-to-html';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.min.css';
import {
    Card,
    CardBody,
    Row,
    Col,
    
} from "reactstrap";

class Add_match extends React.Component{
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        
        this.state = {
          user_id,
          appKey,
          no_users:0,
          no_matches:0,
          no_teams:0,
          tournaments :[],
          teams:[],
          stadiums:[],
          tournamentName : "",
          tournamentStart : "",
          tournamentEnd : "",
          tournament_id : props.match.params.id,
          electric_plug:EditorState.createEmpty(),
          electric_plug_desc:'',
          date: "",
          timeZone:""
        }
        this.getdetailstourna = this.getdetailstourna.bind(this);
        this.matchFrmsubmit = this.matchFrmsubmit.bind(this);
        this.dateChanged = this.dateChanged.bind(this);
      }
      onEditorStateChange = (electric_plug) => {
        this.setState({
          electric_plug,
          electric_plug_desc:draftToHtml(convertToRaw(this.state.electric_plug.getCurrentContent()))
        });
      };
      dateChanged(d){
        this.setState({date: d});
      }
      matchFrmsubmit(e){
        e.preventDefault();
        $("#cover_spin").addClass("cover-spin");
        var tournament_id = $('#tournament option:selected').val();
        var team1_id = $('#team_1 option:selected').val();
        var team2_id = $('#team_2 option:selected').val();
        var stadium_id = $('#stadium option:selected').val();
        var mdate = $('#matchDate').val();
        var mtime = $('#matchTime').val();
        var start_date = mdate+" "+mtime+":00";
        var week = $('#week').val();
        var timeZone = $('#timeZone').val();
        var electric_plug = $('#electric_plug_desc').val();
        $.ajax({
          type: 'POST',
          url:  window.$base_url+'/v1/auth/addTournamentMatch',
          headers: {'App-Secret-Key': this.state.appKey,
          'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Basic YWRtaW46MTIzNA=='
            },
          data :{
                user_id : this.state.user_id,
                tournament_id:tournament_id,
                team1_id:team1_id,
                team2_id:team2_id,
                stadium_id:stadium_id,
                start_date:start_date,
                week:week,
                timeZone:timeZone,
                electric_plug:electric_plug

              },
          success : function(data) {
            $("#cover_spin").removeClass("cover-spin");
            if(data.status === true){ 
              toast.success(data.msg); 
             window.location.href="/admin/matchlist/"+$('#tournament option:selected').val();
            }else{
              toast.error(data.msg); 
            }
          }.bind(this),
          statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
          complete : function(){
          },
          error: function (xhr,ajaxOptions,throwError){
            $("#cover_spin").removeClass("cover-spin");
            toast.error(xhr.responseJSON.msg);
          },
        });

      }
      getdetailstourna(id){
         
        $(()=>{
          var id = $('#tournament option:selected').val();
           
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/tournamentDetails',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id,tournament_id:id},
              success : function(data) {
                if(data.status === true){
                  
                  this.setState({
                    tournamentName : data.data.name,
                    tournamentStart : data.data.start_date,
                    tournamentEnd : data.data.end_date,
                    tournament_id : data.data.id,
                  })
                }
               
              
            }.bind(this),
            complete : function(){
             
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
             
          });
      }
      componentDidMount() {
        $(()=>{
          
          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                
                  this.setState({
                    tournaments:data.data,
                    
                })
                
              }
          }.bind(this),
          statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });

          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/teamList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
               
                  this.setState({
                    teams:data.data
                })
              }
             
            
          }.bind(this),
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/stadiumList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                  this.setState({
                    stadiums:data.data,
                   
                })
              }
            }.bind(this),
            complete : function(){
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });

        });
        $(()=>{
          var id = $('#tournament option:selected').val();
          $("#cover_spin").addClass("cover-spin");
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/tournamentDetails',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id,tournament_id:this.state.tournament_id},
              success : function(data) {
                $("#cover_spin").removeClass("cover-spin");
                if(data.status === true){
                  
                  this.setState({
                    tournamentName : data.data.name,
                    tournamentStart : data.data.start_date,
                    tournamentEnd : data.data.end_date,
                    tournament_id : data.data.id,
                  })
                }
               
              
            }.bind(this),
            complete : function(){
             
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
             
          });
      }
    render(){
        let logined = true;
        const userData = localStorage.getItem("userData");
  
        if(userData === null){
            logined = false;
        }
        if(logined === false){
          return <Redirect to="/admin/login"></Redirect> 
       }
       const { tournaments,teams,stadiums, electric_plug} = this.state;
      
        return(
            <div className="MainDiv content">
                <Row className="align-items-center">
                    <Col lg="12">
                        <h5 className="head">Add Match</h5>
                       
                    </Col>
                </Row>
                <Card className="mt-4">
                    <CardBody>
                        <form>
                        <input type="hidden" name="electric_plug_desc" id="electric_plug_desc" value={this.state.electric_plug_desc} />
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label htmlFor="tournament" className="mb-2">Select Tournaments</label><br/>
                                        <select name="tournament" id="tournament" onChange={() => this.getdetailstourna(this.value)} className="selectbar form-select" required>
                                            <option value="" >Select Tournaments</option>
                                            {tournaments.map(({id,name}) => (
                                            <option value={id} selected={ this.state.tournament_id === id?"Selected":""} >{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <Row className="mt-2">
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Tournaments Name</h5>
                                    <p>{this.state.tournamentName}</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Start Date</h5>
                                    <p>{this.state.tournamentStart}</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>End Date</h5>
                                    <p>{this.state.tournamentEnd}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr className="my-4"/>
                        <form onSubmit={this.matchFrmsubmit}>
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 1</label>
                                        <select className="selectbar" name="team_1" id="team_1" required>
                                            <option value="">Select Team</option>
                                            {teams.map(({id,name}) => (
                                            <option value={id}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 2</label>
                                        <select className="selectbar" name="team_2" id="team_2" required>
                                        <option value="">Select Team</option>
                                            {teams.map(({id,name}) => (
                                                <option value={id}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="form-group">
                                        <label className="form-label">Select Date</label>
                                        <br/>
                                        <DatePicker selected={this.state.date}
                                        onChange={this.dateChanged} 
                                        name="matchDate"
                                        className="form-control"
                                        id="matchDate"
                                        dateFormat="yyyy-MM-dd"
                                        minDate={new Date(this.state.tournamentStart)}
                                        maxDate={new Date(this.state.tournamentEnd)}
                                        placeholderText="Select Date"
              />
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="form-group">
                                        <label className="form-label">Select Time</label>
                                        <input type="time"  className="form-control" name="matchTime" id="matchTime" required />
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="form-group">
                                        <label className="form-label">Select Time Zone</label>
                                        <select className="selectbar form-select" name="timeZone" id="timeZone" required>
                                            <option value="" >Select Time zone</option>
                                              <option value="CST">CT</option>
                                              <option value="EST">ET</option>
                                              <option value="MST">MT</option>
                                              <option value="PST">PT</option>
                                            </select>
                                    </div>   
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Stadium</label>
                                        <select className="selectbar form-select" name="stadium" id="stadium" required>
                                            <option value="" >Select Stadium</option>
                                            {stadiums.map(({id,name}) => (
                                                <option value={id}>{name}</option>
                                            
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Week</label>
                                        <input type="number" min="0" step="any" className="form-control" name="week" id="week" required />
                                    </div>
                                </Col>
                                <Col lg="12">
                                    <div className="form-group">
                                        <label className="form-label">Electric Plug</label>
                                       <Editor
                                                editorState={electric_plug}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                    </div>
                                </Col>
                                <Col lg="12" className="mt-2 text-right">
                                    <button type="submit" className="blue-btn">Add Match</button>
                                    <Link to={"/admin/matchlist/"+this.state.tournament_id}>
                                        <button type="reset" class="red-btn" data-dismiss="modal">Cancel</button>
                                    </Link>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <ToastContainer
                  position="top-right"
                  hideProgressBar={false}
                  autoClose={false}
                  newestOnTop={true}
                  closeOnClick={false}
                  draggable={false}
                  rtl={false}
                />
            </div>
            
        );
    }
}

export default Add_match;