import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import $ from 'jquery'; 
import { Editor } from 'react-draft-wysiwyg';
import { EditorState,convertToRaw,ContentState, convertFromHTML } from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; 
import draftToHtml from 'draftjs-to-html';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class add_prediction extends React.Component{
    constructor(props) {
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.defaultOption = { "id": "-1", "value": "" };
        this.state = {
            user_id,
            appKey,
            question: "",
            tournament_id:"",
            match_id:"",
            algorithm_level:"",
            team1_desc:"",
            team2_desc:"",
            point_table_desc:"",
            algorithm_desc:"",
            house_desc:"",
            fbse:[],
            house_level:"",
            house_desc:"",
            isPaid:"",
            team1_fullname:"",
            team1_profile:"",
            team1_id:"",
            team2_fullname:"",
            team2_profile:"",
            team2_id:"",
            tournaments :[],
            teams:[],
            matches:[],
            editorState: EditorState.createEmpty(),
            editorState_point_table:EditorState.createEmpty(),
            editorStateTeam2:EditorState.createEmpty(),
            editorStateOnhouse:EditorState.createEmpty(),
            editorStateAlgorithm:EditorState.createEmpty(),
            options: [this.defaultOption]
        }
        this.getdetailstourna = this.getdetailstourna.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.teamChange = this.teamChange.bind(this);
    }
    teamChange = (event) =>{
       
        this.setState({
            team1_fullname:event.target.selectedOptions[0].getAttribute('team1_fullname'),
            team1_profile:event.target.selectedOptions[0].getAttribute('team1_profile'),
            team1_id:event.target.selectedOptions[0].getAttribute('team1_id'),
            team2_fullname:event.target.selectedOptions[0].getAttribute('team2_fullname'),
            team2_profile:event.target.selectedOptions[0].getAttribute('team2_profile'),
            team2_id:event.target.selectedOptions[0].getAttribute('team2_id'),

        });
        
    }
    
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            team1_desc:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        });
    };
    onEditorStateChangePointTable = (editorState_point_table) => {
        this.setState({
            editorState_point_table,
            point_table_desc:draftToHtml(convertToRaw(this.state.editorState_point_table.getCurrentContent()))
        });
    };
    onEditorStateChangeTeam2 = (editorStateTeam2) => {
        this.setState({
            editorStateTeam2,
            team2_desc:draftToHtml(convertToRaw(this.state.editorStateTeam2.getCurrentContent()))
        });
    };
    onEditorStateChangeAlgorithm = (editorStateAlgorithm) => {
        this.setState({
            editorStateAlgorithm,
            algorithm_desc:draftToHtml(convertToRaw(this.state.editorStateAlgorithm.getCurrentContent()))
        });
    };
    onEditorStateChangeOnHouse = (editorStateOnhouse) => {
        this.setState({
            editorStateOnhouse,
            house_desc:draftToHtml(convertToRaw(this.state.editorStateOnhouse.getCurrentContent()))
        });
    };
    handleChange = (e) => {
        let { id, value } = e.target;
        this.setState({ [id]: value })
    }
    handleOptionsChange = (index, e) => {
        let { id, value } = e.target;
        let stateOptionsClone = JSON.parse(JSON.stringify(this.state.options));
        stateOptionsClone[index].value = value;
        this.setState({ options: stateOptionsClone });
    }
    handleSave = (e) => {
        e.preventDefault();
    }
    resetQuestion = () => {
        let emptyQuestion = "";
        return emptyQuestion
    }
    resetOptions = (options) => {
        let emptyOptions = options.map(
            (data) => {
                data.value = '';
                return data;
            }
        )
        return emptyOptions;
    }
    handleReset = (e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state));
        let emptyQuestion = this.resetQuestion();
        let emptyOptions = this.resetOptions(stateClone.options);
        this.setState({ question: emptyQuestion, options: emptyOptions });
        e.preventDefault();
    }
    handleDelete = (index, e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state.options));
        stateClone.splice(index, 1);
        this.setState({ options: stateClone });
        e.preventDefault();
    }
    handleClick = (e) => {
        let stateClone = JSON.parse(JSON.stringify(this.state));
        stateClone.options.push(this.defaultOption);
        this.setState({ options: stateClone.options });
        e.preventDefault();
    }
    
    formSubmit(e){
        e.preventDefault();
        
        var form = $("#addPrediction");
      var ddd = form.serializeArray();
      var indexed_array = {};
      var object = {};
      ddd.forEach(function(value, key){
          object[key] = value;
      });
      var json = JSON.stringify(object);
    $.map(ddd, function(n, i){
        indexed_array[n['name']] = n['value'];
        
    });

    
     
     
    $("#cover_spin").addClass("cover-spin");
        
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/addPrediction',
              headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Authorization':'Basic YWRtaW46MTIzNA==',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
              data :ddd,
              
              success : function(data) {
                if(data.status === true){
                    toast.success(data.msg); 
                    $("#cover_spin").removeClass("cover-spin");
                    window.location.href="/admin/prediction";
                  
                }else{
                    toast.error(data.msg);
                    $("#cover_spin").removeClass("cover-spin");
                    this.setState({
                        error:true
                    })
                }
            }.bind(this),
           
            error: function (xhr,ajaxOptions,throwError){
                $("#cover_spin").removeClass("cover-spin");
                toast.error(xhr.responseJSON.msg);
            },
          });
    }
    getdetailstourna(id){
         
        $(()=>{
          var id = $('#tournament_id option:selected').val();
           
          $.ajax({
              type: 'POST',
              url: window.$base_url+'/v1/auth/tournamentMatchList',
              headers: {'App-Secret-Key': this.state.appKey,
              'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization':'Basic YWRtaW46MTIzNA=='
            },
              data :{user_id : this.state.user_id,tournament_id:id},
              success : function(data) {
                if(data.status === true){
                  
                  this.setState({
                    matches:data.data
                  })
                }
               
              
            }.bind(this),
            complete : function(){
             
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });
             
          });
      }
    componentDidMount() {
        $(()=>{
         
          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/tournamentList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
                
                  this.setState({
                    tournaments:data.data,
                    
                })
                
              }
          }.bind(this),
          statusCode: {
                401: function() {
                    window.location.href="/admin/logout/"
                }
            },
            complete : function(){
              
            },
              error: function (xhr,ajaxOptions,throwError){
              //Error block 
            },
          });

          $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/teamList',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id},
            success : function(data) {
              if(data.status === true){
               
                  this.setState({
                    teams:data.data
                })
              }
             
            
          }.bind(this),
          statusCode: {
            401: function() {
                window.location.href="/admin/logout/"
            }
        },
          complete : function(){
            
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
       

        });
      }
    render(){
        let logined = true;
        const userData = localStorage.getItem("userData");
  
        if(userData === null){
            logined = false;
        }
        if(logined === false){
          return <Redirect to="/admin/login"></Redirect> 
       }
       const { tournaments,teams,matches} = this.state;
        let { question, options, team1_profile,team1_fullname, team2_profile, team2_fullname, team1_id, team2_id} = this.state;
        
        const {editorState,editorState_point_table, editorStateTeam2, editorStateAlgorithm, editorStateOnhouse} = this.state;
        $("#team1_desc").val(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
        $("#team2_desc").val(draftToHtml(convertToRaw(this.state.editorStateTeam2.getCurrentContent())));
        $("#point_table_desc").val(draftToHtml(convertToRaw(this.state.editorState_point_table.getCurrentContent())));
        $("#algorithm_desc").val(draftToHtml(convertToRaw(this.state.editorStateAlgorithm.getCurrentContent())));
        $("#house_desc").val(draftToHtml(convertToRaw(this.state.editorStateOnhouse.getCurrentContent())));
        return(
            <>
                <div className="MainDiv content">
                    <Row>
                        <Col lg="12">
                            <Row>
                                <Col lg="6">
                                    <h5 className="head">Add Prediction</h5>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <form onSubmit={this.formSubmit} id="addPrediction">
                        <input type="hidden" name="user_id" value={this.state.user_id} />
                        <input type="hidden" name="team1_desc" id="team1_desc" />
                        <input type="hidden" name="team2_desc" id="team2_desc" />
                        <input type="hidden" name="point_table_desc" id="point_table_desc" />
                        <input type="hidden" name="algorithm_desc" id="algorithm_desc"  />
                        <input type="hidden" name="house_desc"   id="house_desc" />
                        <Card className="mt-4">
                            <CardBody>
                                
                                    <Row>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">Select Tournaments</label><br/>
                                                <select name="tournament_id" id="tournament_id" onChange={() => this.getdetailstourna(this.value)} className="selectbar">
                                                <option value="" >Select Tournaments</option>
                                                {tournaments.map(({id,name}) => (
                                                <option value={id}  >{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">Select Match</label><br/>
                                                <select name="match_id" id="match_id"  onChange={this.teamChange} className="selectbar">
                                                    <option value="">Select Match</option>
                                                    {matches.map(({id,team1_name,team2_name, team1_fullname,team1_profile,team2_fullname, team2_profile, team1_id, team2_id}) => (
                                                        <option value={id} team1_fullname={team1_fullname} 
                                                        team1_profile={team1_profile} 
                                                        team2_fullname={team2_fullname}
                                                        team2_profile={team2_profile}
                                                        team1_id={team1_id}
                                                        team2_id={team2_id}
                                                        >{team1_name+" VS "+team2_name} </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="4">
                                            <div className="form-group">
                                                <label className="mb-2">FREE / PAID</label><br/>
                                                <select id="isPaid" name="isPaid" className="selectbar">
                                                    <option >Select Match</option>
                                                    <option value="0">FREE</option>
                                                    <option value="1">PAID</option>
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        {team1_profile !== ""&&<><Col lg="6">
                                        <img className="img-40 mr-2 rounded-circle align-top" style={{width:'50px',height:'50px'}} src={'https://admin.hiphopandhomeruns.com/API/uploads/team/'+team1_profile} alt={team1_fullname}/>
                                        <p>{team1_fullname}</p>
                                       </Col>  
                                        <Col lg="6">
                                            <img className="img-40 mr-2 rounded-circle align-top" style={{width:'50px',height:'50px'}} src={'https://admin.hiphopandhomeruns.com/API/uploads/team/'+team2_profile} alt={team2_fullname}/>
                                            <p>{team2_fullname}</p>            
                                        </Col></>}
                                    </Row>
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">Algorithm</h5>
                                
                                    <Row>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level</label>
                                                <input type="number" step="any" name="algorithm_level" id="algorithm_level" className="form-control" required/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level For Team</label>
                                               <select id="algorithm_level_team" name="algorithm_level_team" className="selectbar">
                                                   <option value={team1_id}>{team1_fullname}</option>
                                                    <option value={team2_id}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line </label>
                                                <input type="number" step="any" name="algorithm_line" id="algorithm_line" className="form-control" required/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line For Team</label>
                                                <select id="algorithm_line_team" name="algorithm_line_team" className="selectbar">
                                                   <option value={team1_id}>{team1_fullname}</option>
                                                    <option value={team2_id}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div class="form-group">
                                                <label className="form-label">Description</label>
                                               <Editor
                                                editorState={editorStateAlgorithm}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeAlgorithm}
                                            />
                                            </div>
                                        </Col>
                                    
                                    </Row>
                                   
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">Trend & Analysis</h5>
                                
                                    <Row>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Team 1 Description</label>
                                                <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Team 2 Description</label>
                                                <Editor
                                                editorState={editorStateTeam2}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeTeam2}
                                            />
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Enter Point Table Description</label>
                                                <Editor
                                                editorState={editorState_point_table}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangePointTable}
                                            />
                                            </div>
                                        </Col>
                                    
                                    </Row>
                                
                            </CardBody>
                        </Card>
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">FBSE</h5>
                                
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 1</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[0][team_id]" className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <input type="text" name="fbse[0][sportBook]"   className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][monday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][wednesday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[0][friday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 2</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[1][team_id]" className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <input type="text" name="fbse[1][sportBook]"  className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][monday]" step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][wednesday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[1][friday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" className="sport-head">
                                            <h5>Sportbook 3</h5>
                                            <hr className="sportline" />
                                        </Col>
                                        <Col lg="3" className="day-name">Team</Col>
                                        <Col lg="3" className="day-name">SportBook</Col>
                                        <Col lg="2" className="day-name">Monday</Col>
                                        <Col lg="2" className="day-name">Wednesday</Col>
                                        <Col lg="2" className="day-name">Friday</Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <select name="fbse[2][team_id]" className="pl-2 form-control">
                                                    <option >Select Team</option>
                                                    {teams.map(({id,name}) => (
                                                    <option value={id}>{name}</option>
                                                
                                                ))}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="3" className="mt-2">
                                            <div className="form-group">
                                                <input type="text" name="fbse[2][sportBook]"  className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][monday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][wednesday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                        <Col lg="2" className="mt-2">
                                            <div className="form-group">
                                                <input type="number" name="fbse[2][friday]"  step="any" className="form-control" placeholder="Enter Value"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                
                                
                            </CardBody>
                        </Card>
                        
                        <Card className="mt-4">
                            <CardBody>
                                <h5 className="prediction-head">On The House</h5>
                                
                                    <Row>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level</label>
                                                <input type="number" step="any" name="house_level" id="house_level" className="form-control" placeholder="Enter Leval"/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Level For Team</label>
                                                <select id="house_level_team" name="house_level_team" className="selectbar">
                                                   <option value={team1_id}>{team1_fullname}</option>
                                                    <option value={team2_id}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line</label>
                                                <input type="number" step="any" name="house_line" id="house_line" className="form-control" placeholder="Enter Line"/>
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="form-group">
                                                <label className="form-label">Enter Line For Team</label>
                                                <select id="house_line_team" name="house_line_team" className="selectbar">
                                                   <option value={team1_id}>{team1_fullname}</option>
                                                    <option value={team2_id}>{team2_fullname}</option>
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="form-group">
                                                <label className="form-label">Description</label>
                                                <Editor
                                                editorState={editorStateOnhouse}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChangeOnHouse}
                                            />
                                            
                                            </div>
                                        </Col>
                                        <Col lg="12">
                                            <div className="text-right">
                                                <button type="submit" className="blue-btn mr-0">PUBLISH</button>
                                            </div>
                                        </Col>
                                    </Row>
                                
                            </CardBody>
                        </Card>
                    </form>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />
            </>
        );
    }
}

export default add_prediction;
