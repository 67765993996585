import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import $ from 'jquery';
import { read, utils, writeFile } from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Import extends React.Component {
    constructor(props){
        super(props);
        let user_id;
        const userData = localStorage.getItem("userData");
        let appKey = localStorage.getItem("appKey");
        appKey = appKey.replace(/['"]+/g, '');
        
        if(userData !== null){
          const users = JSON.parse(userData);
          user_id = users.id;
        }
        this.state = {
            user_id,
            appKey,
            users :[],
            importData:[],
            

        }
        this.handleImport = this.handleImport.bind(this);
        this.importPostHandler = this.importPostHandler.bind(this);
    }
   componentDidMount(){
        $(()=>{
            $.ajax({
                type: 'POST',
                url: "https://thingproxy.freeboard.io/fetch/https://admin.hiphopandhomeruns.com/API/v1/auth/import",
                headers: {'App-Secret-Key': this.state.appKey,
                'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                'Authorization':'Basic YWRtaW46MTIzNA==',
                
            },
              data :{user_id : this.state.user_id},
              success : function(data) {
               
               
            }.bind(this),
            statusCode: {
                401: function() {
                  window.location.href="/admin/logout/"
                }
              },
              complete : function(){
                
              },
                error: function (xhr,ajaxOptions,throwError){
                //Error block 
              },
            });
        })
    }
    importPostHandler = () =>{
      $(()=>{
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/import',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Authorization':'Basic YWRtaW46MTIzNA==',
            
        },
          data :{user_id : this.state.user_id, importData : this.state.importData},
          success : function(data) {
            $('#importModal').modal('toggle');
           if(data.status){
            toast.success(data.msg); 
           }else{
            toast.error(data.msg); 
           }
           
        }.bind(this),
        statusCode: {
            401: function() {
              window.location.href="/admin/logout/"
            }
          },
          complete : function(){
            this.setState({  importData:[] })
          }.bind(this),
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
    })
    }
  
      handleImport = ($event) => {
          const files = $event.target.files;
          if (files.length) {
              const file = files[0];
              const reader = new FileReader();
              reader.onload = (event) => {
                  const wb = read(event.target.result);
                  const sheets = wb.SheetNames;
  
                  if (sheets.length) {
                      const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                      this.setState({
                        importData:rows
                                    })
                  }
              }
              reader.readAsArrayBuffer(file);
          }
      }
  
  render() {
    const { importData } = this.state; 
    console.log("importData : ", importData);
   
    return (
      <>
       
       <button className="mb-3 blue-btn" data-target="#importModal" data-toggle="modal">Import</button>
       <div className="add-edit-team">
                <div className="modal fade" id="importModal" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <form className="add-stadium" >
                            <div className="col-12 p-0 text-left">
                            <div className="form-group">
                              <label htmlFor="xlsxfile">Select Excel</label>
                              <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={this.handleImport} id="xlsxfile" name="xlsxfile" />
                              
                            </div>
                            <button type="button" onClick={this.importPostHandler} className="btn btn-primary">Import Data</button> 
                                      
                                  </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
              position="top-right"
              hideProgressBar={false}
              autoClose={false}
              newestOnTop={true}
              closeOnClick={false}
              draggable={false}
              rtl={false}
          />
      </>
    );
  }
}



export default Import;