import React from "react";
import NotificationAlert from "react-notification-alert";
import Add_stadium from "components/Stadium/Add_stadium";
import { Link, Redirect } from "react-router-dom";
import stadium1 from "assets/img/arizona_cardinals.jpg"
import stateCity from "./../../src/US_States_and_Cities.json"
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  Row,
  Col,
} from "reactstrap";
const createBase64 = (image) =>{
  var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = function () {
          //console.log(reader.result);
          var str = reader.result;
          str = str.replace("data:image/jpeg;base64,", "");
          str = str.replace("data:image/gif;base64,", "");
            str = str.replace("data:image/png;base64,", "");
            str = str.replace("data:image/jpg;base64,", "");
          $("#bannerEdit").val(str);
          $("#backImage").val(str);
       return reader.result;
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
  }
class Stadiumlist extends React.Component {
  constructor(props){
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    this.state = {
      stadiums :[],
      uid :props.match.params.id,
      name: "",
      state_edit: "",
      city_edit: "",
      banner: "",
      user_id,
      appKey,
      file: stadium1,
      fileback:null,
      edit_sname:"",
      selectf:null
    }
    this.deleteStadium = this.deleteStadium.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetFile = this.resetFile.bind(this);
    //this.stateChange = this.stateChange.bind(this);
    this.getStadiumDetails = this.getStadiumDetails.bind(this);
    this.textChange = this.textChange.bind(this);
    this.updateStadium = this.updateStadium.bind(this);
    
  }
  
  updateStadium(e){
    e.preventDefault();
    $("#cover_spin").addClass("cover-spin");
    $(()=>{
      var name = $("#edit_sname").val();
      var state = $("#state_edit").val();
      var id = $("#edit_id").val();
      var city = $("#city_edit").val();
      var banner = $("#bannerEdit").val();
      
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/updateStadium',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id,stadium_id:id,name:name,state,state,city:city,banner:banner},
        success : function(data) {
          if(data.status === true){
            $("#cover_spin").removeClass("cover-spin");
            toast.success(data.msg); 
            window.location.reload(true);
            this.setState({
                
            })
          }else{
            $("#cover_spin").removeClass("cover-spin");
            toast.error(data.msg);
          }
         
        
      }.bind(this),
      complete : function(){
       
      },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
          toast.error(xhr.responseJSON.msg);
      },
    });
       
    });
  }
  onChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      fileback:URL.createObjectURL(event.target.files[0]),
      image_src : event.target.files[0],
      banner_img : createBase64(event.target.files[0]),
      selectf:true
    });
}
resetFile(event) {
    event.preventDefault();
    this.setState({ file: null,selectf: false,fileback:null });
    $("#backImage").val("");
}
textChange(e){
  
  this.setState({
      [e.target.name] : e.target.value,
      
  })	 
}
getStadiumDetails(id){
  $(()=>{
    $("#cover_spin").addClass("cover-spin");
    
  $.ajax({
      type: 'POST',
      url: window.$base_url+'/v1/auth/stadiumDetails',
      headers: {'App-Secret-Key': this.state.appKey,
      'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization':'Basic YWRtaW46MTIzNA=='
    },
      data :{user_id : this.state.user_id,stadium_id:id},
      success : function(data) {
        if(data.status === true){
          $("#cover_spin").removeClass("cover-spin");
          this.setState({
            file : data.data.banner,
            edit_sname:data.data.name,
            state_edit : data.data.state,
            city_edit : data.data.city,
          })
         
          $("#edit_id").val(data.data.id);
        }
       
    }.bind(this),
    complete : function(){
     
    }.bind(this),
      error: function (xhr,ajaxOptions,throwError){
        $("#cover_spin").removeClass("cover-spin");
       
    },
  });
     
  });
}
  deleteStadium(id){
    
      confirmAlert({
        title: '',
        message: 'Are you sure want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
                $(()=>{
                  $("#cover_spin").addClass("cover-spin");
                
                $.ajax({
                    type: 'POST',
                    url: window.$base_url+'/v1/auth/deleteStadium',
                    headers: {'App-Secret-Key': this.state.appKey,
                    'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization':'Basic YWRtaW46MTIzNA=='
                  },
                    data :{user_id : this.state.user_id,stadium_id:id},
                    success : function(data) {
                      if(data.status === true){
                        $("#cover_spin").removeClass("cover-spin");
                        toast.success(data.msg); 
                        window.location.reload(true);
                        this.setState({
                            
                        })
                      }
                    
                    
                  }.bind(this),
                  complete : function(){
                  
                  },
                    error: function (xhr,ajaxOptions,throwError){
                      $("#cover_spin").removeClass("cover-spin");
                      toast.error(xhr.responseJSON.msg);
                  },
                });
                  
                });
              }
            },
            {
              label: 'No',
              
            }
            ]
            })
  }
  componentDidMount() {
  
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
     
    $.ajax({
        type: 'POST',
        url:window.$base_url+'/v1/auth/stadiumList',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id : this.state.user_id},
        success : function(data) {
          if(data.status === true){
            $("#cover_spin").removeClass("cover-spin");
              this.setState({
                stadiums:data.data,
                name:data.data.name,
                state:data.data.state,
                city:data.data.city,
                banner:data.data.banner,
            })
          }
        }.bind(this),
        statusCode: {
          401: function() {
            window.location.href="/admin/logout/"
          }
        },
        complete : function(){
      },
        error: function (xhr,ajaxOptions,throwError){
          $("#cover_spin").removeClass("cover-spin");
      },
    });
       
    });
    
  }
  
  render() {
    let logined = true;
    const userData = localStorage.getItem("userData");
    
          if(userData === null){
              logined = false;
          }
          if(logined === false){
            return <Redirect to="/admin/login"></Redirect> 
         }
    const { stadiums } = this.state; 
    $(()=>{
      $(".stadium_edit").click(function(){
        $("#quoteForm").modal("show");
      });
    });
    return (
      <>
        <div className="content">
          <NotificationAlert ref={this.notificationAlert} />
          <Row className="align-items-center">
            <Col lg="12">
              <Row>
                <Col lg="6" md="12">
                  <h5 className="head">Stadium List</h5>
                  <nav aria-label="breadcrumb">
                   
                  </nav>
                </Col>
                <Col lg="6" md="12">
                  <div className="text-lg-right text-md-left add-main-btn">
                    <button className="blue-btn" data-target="#add-stadium" data-toggle="modal">Add Stadium</button>
                   </div>
                  
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg="12" className="mb-2">
                <div className="row">
                  {
                    stadiums.map(({id,name,state,city,banner}) => (
                      <Col key={id.toString()} lg="3" md="4" sm="6">
                      <div className="stadiumbox-main">
                          <img src={banner} className="img-fluid"/>
                          <h5>{name}</h5>
                          <Row>
                              <Col lg="6">
                                  <p>City</p>
                                  <h6>{city}</h6>
                              </Col>
                              <Col lg="6">
                                  <p>State</p>
                                  <h6>{state}</h6>
                              </Col>
                          </Row>
                          <Row className="mt-2">
                              <Col lg="6" md="6" sm="12">
                                  <button type="button" onClick={() => this.getStadiumDetails(id)} data-id={id} className="stadium_edit" >Edit</button>
                              </Col>
                              <Col lg="6" md="6" sm="12" className="p-lg-0 p-md-0 p-sm-3">
                                  <button type="button" onClick={() => this.deleteStadium(id)} className="stadium_delete">Delete</button>
                              </Col>
                          </Row>
                      </div>
                  </Col>
                    ))}
                </div>
            </Col>
          </Row>
        </div>
        <div className="add-edit-team">
          <div className="modal fade" id="add-stadium" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body p-0">
                  <Add_stadium></Add_stadium>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="add-edit-team">
                <div className="modal fade pl-0" id="quoteForm" tabIndex="-1" role="dialog" aria-labelledby="quoteForm" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                            <form className="add-stadium" onSubmit={this.updateStadium}>
                            <input type="hidden" id="bannerEdit" name="bannerEdit"  />
                            <input type="hidden" id="edit_id" name="edit_id"  />
                                  <div className="col-12 p-0">
                                      <div className="position-relative">
                                          <img style={{ width: "100%" }} src={this.state.file} className="stadium_image"/>
                                          <div className="upload-stadium-main">
                                              <input type="file" accept="image/*" onChange={this.onChange}/>
                                          </div>
                                      </div>
                                      {this.state.selectf && (
                                          <div className="ml-3 text-center">
                                              <button onClick={this.resetFile} className="red-btn mt-2">Remove File</button>
                                          </div>
                                      )}
                                  </div>
                                  <div className="p-3">
                                      <Row>
                                          <div className="col-lg-12">
                                              <div className="form-group">
                                              <label htmlFor="edit_sname">Enter Stadium Name</label>
                                              <input type="text" name="edit_sname" className="form-control" id="edit_sname"  
                                              value={this.state.edit_sname}
                                              onChange={this.textChange} />
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-sm-12">
                                              <div className="form-group">
                                                  <label htmlFor="state_edit">Select State</label><br/>
                                                  <input type="text"name="state_edit" id="state_edit"   className="form-control"
                                onChange={this.textChange} 
                                value={this.state.state_edit} placeholder="Enter State Name"/>
                                                  
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-sm-12">
                                              <div className="form-group">
                                                  <label htmlFor="city_edit">Enter City</label>
                                                  <input type="text"name="city_edit" id="city_edit"   className="form-control"
                                onChange={this.textChange} 
                                value={this.state.city_edit} placeholder="Enter State Name"/>
                                                 
                                              </div>
                                          </div>
                                          <hr className="border-hr"/>
                                          <div className="col-12 text-right">
                                              <button type="submit" className="blue-btn">Update Stadium</button>
                                              <button type="button" className="red-btn" data-dismiss="modal">Cancel</button>
                                          </div>
                                      </Row>
                                  </div>
                              </form> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <ToastContainer
              position="top-right"
              hideProgressBar={false}
              autoClose={false}
              newestOnTop={true}
              closeOnClick={false}
              draggable={false}
              rtl={false}
            />
      </>
    );
  }
}

export default Stadiumlist;
