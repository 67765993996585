import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import $ from 'jquery';
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Edit from "../assets/img/Edit.svg";
import Delete from "../assets/img/Delete.svg";
import { Link } from "react-router-dom";
import profile from "../assets/img/profile.jpg";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

class Matchlist extends React.Component {
  constructor(props){
    super(props);
    let user_id;
    const userData = localStorage.getItem("userData");
    let appKey = localStorage.getItem("appKey");
    appKey = appKey.replace(/['"]+/g, '');
    
    if(userData !== null){
      const users = JSON.parse(userData);
      user_id = users.id;
    }
    this.state = {
      users :[],
      user_id,
          appKey,
      t_id: props.match.params.id
    }
    this.deleteMatch = this.deleteMatch.bind(this)
  }
  componentDidMount() {
  
  
    $(()=>{
      $("#cover_spin").addClass("cover-spin");
     
    $.ajax({
        type: 'POST',
        url: window.$base_url+'/v1/auth/tournamentMatchList',
        headers: {'App-Secret-Key': this.state.appKey,
        'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':'Basic YWRtaW46MTIzNA=='
      },
        data :{user_id :this.state.user_id,tournament_id:this.state.t_id},
        success : function(data) {
          $("#cover_spin").removeClass("cover-spin");
          if(data.status === true){
            
              this.setState({
                users:data.data
            })
          }
         
        
      }.bind(this),
      statusCode: {
        401: function() {
          window.location.href="/admin/logout/"
        }
      },
      complete : function(){
        $('#example').DataTable();
      },
        error: function (xhr,ajaxOptions,throwError){
        //Error block 
      },
    });
       
    });
  }
  deleteMatch(id){
    confirmAlert({
      title: '',
      message: 'Are you sure want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
        $(()=>{
            $("#cover_spin").addClass("cover-spin");
          
        $.ajax({
            type: 'POST',
            url: window.$base_url+'/v1/auth/deleteTournamentMatch',
            headers: {'App-Secret-Key': this.state.appKey,
            'Authorization-Token': 'eyJ0eXA1iOi0JKV1QiL8CJhb5GciTWvLUzI1NiJ9IiRk2YXRh8Ig',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization':'Basic YWRtaW46MTIzNA=='
          },
            data :{user_id : this.state.user_id,match_id:id},
            success : function(data) {
              if(data.status === true){
                $("#cover_spin").removeClass("cover-spin");
                window.location.reload(true);
                this.setState({
                    
                })
              }
            
            
          }.bind(this),
          complete : function(){
          
          },
            error: function (xhr,ajaxOptions,throwError){
            //Error block 
          },
        });
          
        });
      }
    },
    {
      label: 'No',
      
    }
    ]
    })
  }
  render() {
    const { users } = this.state;
    console.log(users);
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="12">
              <Row>
                <Col lg="6" md="12">
                  <h5 className="head">Match Schedule</h5>
                 
                </Col>
                <Col lg="6" md="12">
                  <div className="text-lg-right text-md-left add-main-btn">
                    <Link to={"/admin/add_match/"+this.state.t_id}>
                      <button className="mb-3 blue-btn">Add Match</button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Card className="table-view mt-4">
            <CardBody>
              <table id="example" className="display">
                <thead>
                    <tr>
                      <th>ID</th>
                      <th>Teams</th>
                      <th>Start Date</th>
                      <th>Stadium</th>
                      
                      <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {users.map(({id,start_date,flag,stadium_name,team1_name,team2_name}) => (
                  <tr>
                    <td>{id}</td>
                    <td className="team-list">
                      <div className="d-inline-block align-middle mr-3">
                       
                        <div className="d-inline-block">
                          <span>{team1_name}</span>
                        </div>
                      </div>
                      <div className="d-inline-block align-middle mr-3 vs">VS</div>
                      <div className="d-inline-block align-middle mr-2">
                        
                        <div className="d-inline-block">
                          <span>{team2_name}</span>
                        </div>
                      </div>
                    </td>
                    <td>{start_date}</td>
                    <td>{stadium_name}</td>
                    
                    <td>
                      <Link className="edit-icon" to={"/admin/edit_match/"+id} ><img src={Edit}/></Link>
                      <button onClick={() => this.deleteMatch(id)} className="edit-icon delete-icon"><img src={Delete}/></button>
                    </td>
                  </tr>
                  ))}
                </tbody>
              </table>  
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default Matchlist;
