import React from "react";
import { Link, NavLink } from "react-router-dom";
import $ from 'jquery'; 
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table
} from "reactstrap";

class Edit_match extends React.Component{
    render(){
        return(
            <div className="MainDiv content">
                <Row className="align-items-center">
                    <Col lg="12">
                        <h5 className="head">Edit Match</h5>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item" aria-current="page"><a href="#">Tournaments</a></li>
                                <li className="breadcrumb-item" aria-current="page"><a href="#">National Football League</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Edit Match</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Card className="mt-4">
                    <CardBody>
                        <form>
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label for="exampleInputEmail1" className="mb-2">Select Tournaments</label><br/>
                                        <select className="selectbar form-select">
                                            <option  disabled>Select Tournaments</option>
                                            <option selected >National Football League</option>
                                            <option>Football League</option>
                                            <option>International Football League</option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                        <Row className="mt-2">
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Tournaments Name</h5>
                                    <p>National Football League</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>Start Date</h5>
                                    <p>17 March, 2021</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" className="nfl">
                                <div className="tournaments-section">
                                    <h5>End Date</h5>
                                    <p>20 April, 2021</p>
                                </div>
                            </Col>
                        </Row>
                        <hr className="my-4"/>
                        <form>
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 1</label>
                                        <select className="selectbar">
                                            <option disabled>Select Tournaments</option>
                                            <option selected>National Football League</option>
                                            <option>Football League</option>
                                            <option>International Football League</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Team 2</label>
                                        <select className="selectbar">
                                            <option disabled>Select Tournaments</option>
                                            <option selected>National Football League</option>
                                            <option>Football League</option>
                                            <option>International Football League</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Date</label>
                                        <input type="date" className="form-control"/>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Time</label>
                                        <input type="time" className="form-control"/>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Ground</label>
                                        <select className="selectbar form-select">
                                            <option selected disabled>Select Ground</option>
                                            <option>Arizona Cardinals</option>
                                            <option>Football League</option>
                                            <option>International Football League</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg="12" className="mt-2 text-right">
                                    <button type="button" className="blue-btn">Add Match</button>
                                    <button type="button" className="red-btn mr-0">Cancel</button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default Edit_match;